import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckBox from '../../../Common/CheckBox/CheckBox'
import BizBoardBannerImageControl from './Editor/BizBoardBannerImageControl'
import {
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_MASK_OPTIONS,
  BIZ_BOARD_MASK_TYPE,
  BizBoardImageEditUtil,
  BizBoardImageSpec,
  PAST_BIZ_BOARD_MASK_OPTIONS,
} from './bizBoardUtils'
import { checkNoneEmpty } from '../../../../utils/regexUtils'
import { MomentLoader } from '../../../Common/Loader/MomentLoader'
import { usePrevious } from '../../../../utils/hook/usePrevious'
import BizBoardBannerImageEdit from './Editor/BizBoardBannerImageEdit'
import { keyMirror } from '../../../../utils/utils'
import ExpandableHint from './ExpandableHint/ExpandableHint'
import {
  EXPANDABLE_HINT_PREVIEW_TYPE,
  EXPANDABLE_HINT_TYPE,
} from './ExpandableHint/expandableHintUtils'
import DynamicTooltip from '../../../Tooltip/DynamicTooltip'
import { Tooltip } from '../../../../resources/locale'

const PREVIEW_OPTION = keyMirror({
  GUIDE_LINE: null,
  EXPANDABLE_HINT: null,
})

const BizBoardBannerPreview = ({
  shouldImageCenterCropping,
  base64PreviewImage,
  objetImages,
  formData,
  templateData,
  validationState,
  isLoading,
  isAvailableDownload = false,
  isPending,
  isPastSizeModify,
  handleDownload,
  handleFormChange,
  handleTemplateChange,
  onMaskTypeChange,
}) => {
  const customEventHandlerRef1 = React.useRef()
  const customEventHandlerRef2 = React.useRef()
  const backgroundLayerNodeRef = React.useRef()

  const [previewOptionVisibility, setPreviewOptionVisibility] = React.useState({
    [PREVIEW_OPTION.GUIDE_LINE]: true,
    [PREVIEW_OPTION.EXPANDABLE_HINT]: false,
  })

  const [isMaskedPreviewImageUpdating, setIsMaskedPreviewImageUpdating] =
    React.useState(false)

  const {
    imageIndex: editImageIndex,
    bannerType,
    maskType,
    appDownloadType,
    imageTypeArray,
  } = templateData || {}
  const { badge } = formData || {}

  const prevIsLoading = usePrevious(isLoading)
  const prevMaskType = usePrevious(maskType)
  const useMask = maskType !== BIZ_BOARD_MASK_TYPE.NONE
  const availableMaskOptions = useMemo(
    () =>
      isPastSizeModify &&
      (maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT ||
        maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT)
        ? PAST_BIZ_BOARD_MASK_OPTIONS
        : BIZ_BOARD_MASK_OPTIONS.map(maskOption => {
            const { id } = maskOption
            if (
              id === BIZ_BOARD_MASK_TYPE.BLUR &&
              badge === BIZ_BOARD_BADGE_TYPE.BELT
            ) {
              return { ...maskOption, available: false }
            } else {
              return maskOption
            }
          }),
    [badge, isPastSizeModify, maskType]
  )

  // 썸네일형 마스크 유형 변경 시, express 이미지 갱신될 동안 image 노출 제한.
  React.useEffect(() => {
    if (
      useMask &&
      !isMaskedPreviewImageUpdating &&
      maskType !== prevMaskType &&
      !isLoading
    ) {
      setIsMaskedPreviewImageUpdating(true)
    }

    if (prevIsLoading && !isLoading) {
      setIsMaskedPreviewImageUpdating(false)
    }
  }, [
    useMask,
    isLoading,
    isMaskedPreviewImageUpdating,
    prevIsLoading,
    prevMaskType,
    maskType,
  ])

  const togglePreviewOption = React.useCallback(previewOption => {
    setPreviewOptionVisibility(prev => ({
      ...prev,
      [previewOption]: !prev[previewOption],
    }))
  }, [])

  const errorMessage = useMemo(
    () => validationState[BIZ_BOARD_FORM_VALIDATION_KEY.COPY]?.message,
    [validationState]
  )

  const copyWidth = useMemo(() => {
    const { errorCode } =
      validationState[BIZ_BOARD_FORM_VALIDATION_KEY.COPY] || {}
    const { bannerType, maskType } = templateData
    switch (errorCode) {
      case 'MAIN_COPY_MAX_LENGTH':
        switch (bannerType) {
          case BIZ_BOARD_BANNER_TYPE.OBJECT:
            return 585
          case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
            switch (maskType) {
              case BIZ_BOARD_MASK_TYPE.BLUR:
                return 573
              case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE:
                return 540
              default:
                return 585
            }
          case BIZ_BOARD_BANNER_TYPE.MASKING:
            switch (maskType) {
              case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP:
                return 540
              case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN:
                return 573
              default:
                return 0
            }
          case BIZ_BOARD_BANNER_TYPE.TEXT:
            return 796
          default:
            return 0
        }
      case 'SUB_COPY_MAX_LENGTH':
        switch (bannerType) {
          case BIZ_BOARD_BANNER_TYPE.OBJECT:
            return 585
          case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
            switch (maskType) {
              case BIZ_BOARD_MASK_TYPE.BLUR:
                return 540
              case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE:
                return 540
              default:
                return 585
            }
          case BIZ_BOARD_BANNER_TYPE.MASKING:
            switch (maskType) {
              case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP:
                return 573
              case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN:
                return 540
              default:
                return 0
            }
          case BIZ_BOARD_BANNER_TYPE.TEXT:
            return 796
          default:
            return 0
        }
      case 'COPY_MAX_LENGTH':
        switch (bannerType) {
          case BIZ_BOARD_BANNER_TYPE.OBJECT:
            return 585
          case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
            switch (maskType) {
              case BIZ_BOARD_MASK_TYPE.BLUR:
                return 572
              case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE:
                return 540
              default:
                return 585
            }
          case BIZ_BOARD_BANNER_TYPE.MASKING:
            return 572
          case BIZ_BOARD_BANNER_TYPE.TEXT:
            return 796
          default:
            return 0
        }
      case 'COPY_MIN_LENGTH':
      default:
        return BizBoardImageSpec.getIntrinsicCopyMinimum(templateData)
    }
  }, [templateData, validationState])

  const GuideWidth = useMemo(() => {
    return (
      BizBoardImageSpec.VIEW_BANNER_PADDING_H +
      copyWidth / BizBoardImageSpec.RATIO
    )
  }, [copyWidth])

  const backgroundLayerRect =
    BizBoardImageEditUtil.getBackgroundLayerRect(templateData)

  const dragLayerRects = React.useMemo(
    () =>
      imageTypeArray.map((_, index) =>
        BizBoardImageEditUtil.getDragLayerRect({
          maskType,
          editImageIndex: index,
        })
      ),
    [imageTypeArray, maskType]
  )

  const className = React.useMemo(
    () => getClassName({ bannerType, maskType }),
    [bannerType, maskType]
  )

  const previewImageUrl = base64PreviewImage
    ? `data:image/png;base64,${base64PreviewImage}`
    : null

  const appIconGuideTop = BizBoardImageEditUtil.getAppIconGuideTop(templateData)

  const title =
    templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
    templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING
      ? '소재 미리보기 및 편집'
      : '소재 미리보기'

  const isReadyToDrawImage = useMask ? !isMaskedPreviewImageUpdating : true

  const isImageEditBannerType = React.useMemo(
    () =>
      [BIZ_BOARD_BANNER_TYPE.THUMBNAIL, BIZ_BOARD_BANNER_TYPE.MASKING].includes(
        bannerType
      ),
    [bannerType]
  )

  const isAvailableImageEdit = React.useCallback(
    index =>
      index <= imageTypeArray.length &&
      isImageEditBannerType &&
      checkNoneEmpty(objetImages?.[index], dragLayerRects?.[index]),
    [imageTypeArray, isImageEditBannerType, objetImages, dragLayerRects]
  )

  const isImageControlActive = React.useCallback(
    index => checkNoneEmpty(objetImages?.[index], dragLayerRects?.[index]),
    [objetImages, dragLayerRects]
  )

  const handleBackGroundLayerClick = React.useCallback(() => {
    handleTemplateChange('imageIndex', -1)
  }, [handleTemplateChange])

  return (
    <div className="bnrimg_preview">
      <strong className="tit_layer">{title}</strong>
      <div className="cont_layer">
        <div
          style={{ overflow: 'hidden' }}
          draggable={false}
          className={className}>
          {previewOptionVisibility[PREVIEW_OPTION.GUIDE_LINE] && (
            <Fragment>
              {appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE && (
                <span
                  className="app_image"
                  style={{ top: `${appIconGuideTop}px` }}
                />
              )}
              <CopyMinimumGuide width={GuideWidth} error={!!errorMessage} />
            </Fragment>
          )}
          {bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT && (
            <span className="limit_group">
              {previewOptionVisibility[PREVIEW_OPTION.GUIDE_LINE] && (
                <Fragment>
                  <span
                    className={classNames('limit_image', {
                      error:
                        !!validationState[
                          BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_1
                        ],
                    })}
                  />
                  {maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE && (
                    <span
                      className={classNames('limit_image', {
                        error:
                          !!validationState[
                            BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_2
                          ],
                      })}
                    />
                  )}
                  {bannerType === BIZ_BOARD_BANNER_TYPE.MASKING && (
                    <span className="limit_logo" />
                  )}
                </Fragment>
              )}
            </span>
          )}
          {previewOptionVisibility[PREVIEW_OPTION.GUIDE_LINE] && (
            <Fragment>
              {badge === BIZ_BOARD_BADGE_TYPE.FLAG && (
                <span
                  className={classNames('flag_image', {
                    type_flag2: maskType === BIZ_BOARD_MASK_TYPE.SQUARE,
                  })}
                />
              )}
              {badge === BIZ_BOARD_BADGE_TYPE.BELT && (
                <span className="belt_image" />
              )}
            </Fragment>
          )}
          <div className="inner_bnr">
            {imageTypeArray.map((imageType, index) => {
              return (
                isAvailableImageEdit(index) &&
                isReadyToDrawImage && (
                  <BizBoardBannerImageEdit
                    key={`imageEdit|${imageType}|${index}`}
                    isImageEdit={editImageIndex === index}
                    shouldImageCenterCropping={shouldImageCenterCropping}
                    index={index}
                    image={objetImages?.[index]}
                    dragLayerRect={dragLayerRects?.[index]}
                    imageType={imageType}
                    maskType={maskType}
                    customEventHandlerRef={
                      index === 0
                        ? customEventHandlerRef1
                        : customEventHandlerRef2
                    }
                    backgroundLayerNodeRef={backgroundLayerNodeRef}
                    handleTemplateChange={handleTemplateChange}
                  />
                )
              )
            })}
            <BackgroundLayer
              ref={backgroundLayerNodeRef}
              backgroundLayerRect={backgroundLayerRect}
            />
          </div>
          {isPending && (
            <div className="loading_wrap" style={{ zIndex: 2 }}>
              <span className="reform_area_loading">
                <span className="group_loading">
                  <MomentLoader />
                </span>
              </span>
            </div>
          )}
          {previewImageUrl && isReadyToDrawImage && (
            <img
              draggable={false}
              style={{ position: 'absolute', zIndex: 1, left: 0, top: 0 }}
              src={previewImageUrl}
              width={BizBoardImageSpec.VIEW_BANNER_WIDTH}
              height={BizBoardImageSpec.VIEW_BANNER_HEIGHT}
              onClick={handleBackGroundLayerClick}
            />
          )}
          {previewOptionVisibility[PREVIEW_OPTION.EXPANDABLE_HINT] && (
            <ExpandableHint
              hintType={EXPANDABLE_HINT_TYPE.PLAY}
              previewType={EXPANDABLE_HINT_PREVIEW_TYPE.BIZ_BOARD_BANNER_CREATE}
              zIndex={30}
            />
          )}
        </div>
        {errorMessage && <p className="txt_error">{errorMessage}</p>}
        <PreviewOption
          bannerType={bannerType}
          isAvailableDownload={isAvailableDownload}
          previewOptionVisibility={previewOptionVisibility}
          togglePreviewOption={togglePreviewOption}
          handleDownload={handleDownload}
        />
        {imageTypeArray.map((imageType, index) => {
          return (
            isImageEditBannerType &&
            imageTypeArray.length > index && (
              <BizBoardBannerImageControl
                key={`ImageControl|${imageType}_${index}`}
                index={index}
                badgeType={badge}
                bannerType={bannerType}
                maskType={maskType}
                imageType={imageType}
                imageCount={imageTypeArray.length}
                validationState={validationState}
                availableMaskOptions={availableMaskOptions}
                active={isImageControlActive(index)}
                customEventHandlerRef={
                  index === 0 ? customEventHandlerRef1 : customEventHandlerRef2
                }
                handleTemplateChange={handleTemplateChange}
                handleFormChange={handleFormChange}
                onMaskTypeChange={onMaskTypeChange}
              />
            )
          )
        })}
        <BannerCreateGuide bannerType={templateData.bannerType} />
      </div>
    </div>
  )
}

const BackgroundLayer = React.forwardRef(
  ({ backgroundLayerRect }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        style={{
          width: `${backgroundLayerRect.width}px`,
          height: `${backgroundLayerRect.height}px`,
          top: `${backgroundLayerRect.top}px`,
          left: `${backgroundLayerRect.left}px`,
          position: 'absolute',
          zIndex: 19,
        }}
        draggable={false}
      />
    )
  }
)

BackgroundLayer.displayName = 'BackgroundLayer'
BackgroundLayer.propTypes = {
  backgroundLayerRect: PropTypes.object,
}

const PreviewOption = React.memo(
  ({
    bannerType,
    isAvailableDownload,
    previewOptionVisibility,
    togglePreviewOption,
    handleDownload,
  }) => {
    const handleVisibilityChange = React.useCallback(
      previewOption => () => {
        togglePreviewOption(previewOption)
      },
      [togglePreviewOption]
    )

    return (
      <div className="info_guide">
        <CheckBox
          label="가이드라인 보기"
          id={PREVIEW_OPTION.GUIDE_LINE}
          isChecked={previewOptionVisibility[PREVIEW_OPTION.GUIDE_LINE]}
          onChange={handleVisibilityChange(PREVIEW_OPTION.GUIDE_LINE)}
        />

        <CheckBox
          label="힌트에셋 영역 보기"
          id={PREVIEW_OPTION.EXPANDABLE_HINT}
          isChecked={previewOptionVisibility[PREVIEW_OPTION.EXPANDABLE_HINT]}
          onChange={handleVisibilityChange(PREVIEW_OPTION.EXPANDABLE_HINT)}
        />
        <DynamicTooltip
          content={Tooltip(
            'create_ads.v2.biz_board_banner_create.preview.show_expandable_hint_asset'
          )}>
          <a className="link_g link_help">
            <span className="ico_comm ico_help">도움말</span>
          </a>
        </DynamicTooltip>

        {(bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
          bannerType === BIZ_BOARD_BANNER_TYPE.MASKING) && (
          <p className="desc_preview">
            마우스로 이미지의 사이즈 및 위치를 조정할 수 있습니다.
          </p>
        )}
        <button
          type="button"
          className={classNames('btn_gm gm_bl', {
            in_active: !isAvailableDownload,
          })}
          disabled={!isAvailableDownload}
          onClick={() => {
            if (isAvailableDownload) {
              handleDownload()
            }
          }}>
          <span className="inner_g">배너 다운로드</span>
        </button>
      </div>
    )
  }
)

PreviewOption.displayName = 'PreviewOption'
PreviewOption.propTypes = {
  bannerType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BANNER_TYPE)).isRequired,
  isAvailableDownload: PropTypes.bool.isRequired,
  previewOptionVisibility: PropTypes.shape({
    [PREVIEW_OPTION.GUIDE_LINE]: PropTypes.bool.isRequired,
    [PREVIEW_OPTION.EXPANDABLE_HINT]: PropTypes.bool.isRequired,
  }).isRequired,
  togglePreviewOption: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
}

const BannerCreateGuide = React.memo(({ bannerType }) => {
  const [isOpened, setIsOpened] = React.useState(false)

  return (
    <div className={classNames('box_guide', { fold_on: isOpened })}>
      <strong className="tit_guide">
        빠른 광고 진행을 위해 꼭 지켜주세요!
      </strong>
      <a
        className="btn_more"
        onClick={e => {
          e.preventDefault()
          setIsOpened(prev => !prev)
        }}>
        <span className="ico_comm ico_more">내용 더보기</span>
      </a>
      <ul className="list_guide">
        {bannerType === BIZ_BOARD_BANNER_TYPE.MASKING && (
          <li>마스킹 이미지와 로고 이미지가 겹치지 않게 제작</li>
        )}
        {(bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
          bannerType === BIZ_BOARD_BANNER_TYPE.MASKING) && (
          <li>텍스트가 없는 이미지만 사용</li>
        )}
        {(bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
          bannerType === BIZ_BOARD_BANNER_TYPE.TEXT) && (
          <li>
            카피에 광고주체에 대한 표기 필수
            <br />
            광고주체 예) 카카오게임이 광고주일 경우, 카카오게임 또는 게임명을
            노출
          </li>
        )}
        {bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT && (
          <li>
            카피나 오브젝트에 광고주체에 대한 표기 필수 - 단, 앱다운로드 카피
            사용 시에는 오브젝트에 광고주체 표기 불가
            <br />
            광고주체 예) 카카오게임이 광고주일 경우, 카카오게임 또는 게임명을
            노출
          </li>
        )}
        <li>
          메인 카피와 서브 카피 둘 중 하나는 반드시 텍스트 최소 길이를 준수해서
          제작
        </li>
        <li>
          자판 외 특수기호 및 화살표(→)만 사용 가능 ex) <del>59,900</del>→
          39,900
        </li>
        {bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT && (
          <Fragment>
            <li>
              소재 랜딩을 카카오서비스로 연결할 경우 ‘배지 사용 설정’ 선택 필수
            </li>
            <li>
              소재 랜딩을 앱 마켓/스토어로 연결할 경우 ‘앱다운로드 설정’ 선택
              필수
            </li>
            <li>앱다운로드 카피는 “앱” 텍스트를 반드시 포함해서 제작</li>
          </Fragment>
        )}
        {bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && (
          <li>미리보기 영역에서 마우스로 오브젝트 이미지 위치 조정 가능</li>
        )}
      </ul>
    </div>
  )
})

BannerCreateGuide.displayName = 'BannerCreateGuide'
BannerCreateGuide.propTypes = {
  bannerType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BANNER_TYPE)).isRequired,
}

const CopyMinimumGuide = React.memo(({ width, error }) => {
  return (
    <Fragment>
      <span
        className={classNames('min_text', { error })}
        style={{ left: `${width - 1}px` }}
      />
      <span
        style={{
          width: `${width}px`,
          position: 'absolute',
          top: 0,
          left: 0,
          height: '160px',
          borderRadius: '9px 0 0 9px',
          background: error ? 'rgba(244,84,84,.08)' : 'rgba(0,0,0,0.015)',
          zIndex: 10,
        }}
      />
    </Fragment>
  )
})

CopyMinimumGuide.displayName = 'CopyMinimumGuide'
CopyMinimumGuide.propTypes = {
  width: PropTypes.number.isRequired,
  error: PropTypes.bool,
}

const getClassName = ({ bannerType, maskType }) => {
  return classNames('bg_bnr', {
    set_bnr: false,
    bnr_inlanding: bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT,
    bnr_round:
      bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
      maskType === BIZ_BOARD_MASK_TYPE.SQUARE,
    bnr_blur:
      bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
      maskType === BIZ_BOARD_MASK_TYPE.BLUR,
    bnr_multi:
      bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
      maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE,
    bnr_semicircle:
      bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
      maskType === BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN,
    bnr_semicircle2:
      bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
      maskType === BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP,
    bnr_cylinder:
      bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT,
    bnr_cylinder2:
      bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT,
    bnr_text: bannerType === BIZ_BOARD_BANNER_TYPE.TEXT,
  })
}

BizBoardBannerPreview.propTypes = {
  shouldImageCenterCropping: PropTypes.bool,
  base64PreviewImage: PropTypes.string,
  objetImages: PropTypes.array,
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  isLoading: PropTypes.bool,
  isAvailableDownload: PropTypes.bool,
  isPending: PropTypes.bool,
  isPastSizeModify: PropTypes.bool,
  handleDownload: PropTypes.func,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
  onMaskTypeChange: PropTypes.func,
  onResize: PropTypes.func,
}

export default BizBoardBannerPreview
