import {
  CONTEXTPATH,
  fetchGet,
  fetchPost,
  fetchPostForm,
  fetchPut,
} from '../../utils/fetchUtils'
import { CreativeHelper } from '../../utils/helper/helper-creative'
import { v4 as uuid } from 'uuid'
import RequestLock from '../../utils/requestLock'
import { queryString } from '../../utils/utils'
import { fetchPostFormExternal } from '../../utils/externalFetchUtils'

/**
 * 5.1.2 NEW 멀티 소재 파일 업로드
 *
 * tenth response -> image asset 으로 강제 convert
 */
function uploadCreativeImages(
  adAccountId,
  formData,
  onProgress,
  cancelTokenSource
) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload/creativeImages`,
    formData,
    adAccountId,
    onProgress,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data.successFiles =
        response.data.successFiles?.map(file => {
          return { ...CreativeHelper.Image.toAPI(file), imageUUID: uuid() }
        }) || []
    }
    return response
  })
}

/**
 * 5.1.3 NEW 멀티 소재 파일 업로드(제약조건X)
 *
 * tenth response -> image asset 으로 강제 convert
 */
function uploadCreativeImagesNoConstraint(
  adAccountId,
  formData,
  onProgress,
  cancelTokenSource
) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload/creativeImages?without=SIZE_VALID`,
    formData,
    adAccountId,
    onProgress,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data.successFiles =
        response.data.successFiles?.map(file => {
          return { ...CreativeHelper.Image.toAPI(file), imageUUID: uuid() }
        }) || []
    }
    return response
  })
}

/**
 * 5.1.4 멀티 소재 파일 업로드 with Base64
 *
 * tenth response -> image asset 으로 강제 convert
 */
function uploadMultipleAdCreativeImagesWithBase64(
  adAccountId,
  body,
  onProgress,
  cancelTokenSource
) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload/creativeImagesWithBase64`,
    body,
    adAccountId,
    onProgress,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data.successFiles =
        response.data.successFiles?.map(file => {
          return { ...CreativeHelper.Image.toAPI(file), imageUUID: uuid() }
        }) || []
    }
    return response
  })
}

/**
 * 5.1.5 소재 파일 업로드 with Base64
 */
function uploadCreativeImageBase64(adAccountId, body) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload/base64`,
    body,
    adAccountId
  ).then(response => {
    if (response?.data) {
      response.data = {
        ...CreativeHelper.Image.toAPI(response.data),
        imageUUID: uuid(),
      }
    }
    return response
  })
}

function uploadCreativeImage(adAccountId, body, onProgress, cancelTokenSource) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload`,
    body,
    adAccountId,
    onProgress,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data = {
        ...CreativeHelper.Image.toAPI(response.data),
        imageUUID: uuid(),
      }
    }
    return response
  })
}

/**
 * 동영상을 업로드할 tenth upload path 획득
 */
function getVideoUploadTenthPath({
  adAccountId,
  fileName,
  loudnorm,
  transcodeType,
}) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/video/uploadPath?${queryString({
      fileName,
      loudnorm,
      transcodeType,
    })}`,
    adAccountId
  )
}

/**
 * 텐스에 동영상 파일 업로드
 */
function uploadCreativeVideoToTenth({
  url,
  formData,
  cancelTokenSource,
  onProgress,
}) {
  return fetchPostFormExternal(url, formData, cancelTokenSource, onProgress)
}

/**
 * 텐스에 동영상 파일 올린 후 받은 response 를 코어에 전송
 */
function getCreativeVideoInfoByFileName({ adAccountId, fileName, body }) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video?${queryString({ fileName })}`,
    body,
    adAccountId
  ).then(response => {
    if (response.data) {
      response.data = {
        ...CreativeHelper.Video.toAPI(response.data),
        videoUUID: uuid(),
      }
    }
    return response
  })
}

/**
 * 5.2.8 메시지 비디오를 카카오 TV에 등록하는 URL 추출
 */
function getMessageCreativeVideoUploadUrlToKakaoTv({ adAccountId }) {
  return fetchGet(`${CONTEXTPATH}/creatives/video/uploadInfos`, adAccountId)
}

/**
 * 5.2.9 카카오TV에 동영상 파일 업로드
 */
function uploadMessageCreativeVideoToKakaoTv({
  formData,
  adAccountId,
  onProgress,
  cancelTokenSource,
}) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/video/upload`,
    formData,
    adAccountId,
    onProgress,
    cancelTokenSource
  )
}

/**
 * 5.2.9 카카오TV에 URL 이용하여 동영상 파일 업로드
 */
function uploadMessageCreativeVideoUrlToKakaoTv({
  body,
  adAccountId,
  cancelTokenSource,
}) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/uploadWithUrl`,
    body,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 5.2.10 메시지 비디오 업로드 완료 후 인코딩 요청
 */
function requestMessageCreativeVideoEncoding({ adAccountId, body }) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/uploadComplete`,
    body,
    adAccountId
  )
}

/**
 * 5.2.11 카카오TV 영상 인코딩 progressing info check
 */
function getMessageCreativeVideoEncodingProgressingInfo({ adAccountId, vid }) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/video/progressingInfo?vid=${vid}`,
    adAccountId
  )
}

/**
 * 5.2.12 카카오TV 영상 정보 확인
 */
function getMessageCreativeVideoKakaoTvInfo({ adAccountId, vid }) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/video/movieInfo?vid=${vid}`,
    adAccountId
  ).then(response => {
    if (response.data) {
      const {
        thumbnailUrlMap = {},
        width,
        height,
        rotate,
        originalFileName = '-',
      } = response.data

      response.data = {
        ...CreativeHelper.Video.toAPI(response.data),
        thumbnail: {
          url: Object.values(thumbnailUrlMap)?.[0],
          imageWidth: rotate === 90 || rotate === 270 ? height : width,
          imageHeight: rotate === 90 || rotate === 270 ? width : height,
          originalFileName,
          fileName: originalFileName,
        },
        videoUUID: uuid(),
      }
    }

    return response
  })
}

/**
 * 5.2.14 카카오TV 영상 정보 수정
 */
function modifyMessageCreativeVideoInfo({ adAccountId, formData }) {
  return RequestLock.acquire({
    key: 'modifyKakaoTvVideoInfo',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/creatives/video/movieInfo`,
        formData,
        adAccountId
      ).finally(() => {
        done()
      }),
  })
}

/**
 * 5.2.15 카카오TV 미리보기를 위한 토큰 발급
 */
function getMessageCreativeVideoPreviewToken({ adAccountId, clipLinkId }) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/video/token?clipLinkId=${clipLinkId}`,
    adAccountId
  )
}

function uploadAdCreativeImageByURL(adAccountId, url, cancelTokenSource) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/upload?by=URL`,
    { url },
    adAccountId,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data = {
        ...CreativeHelper.Image.toAPI(response.data),
        imageUUID: uuid(),
      }
    }
    return response
  })
}

function uploadAdCreativeImageAndCropByURL(
  adAccountId,
  url,
  cropWidth,
  cropHeight,
  cancelTokenSource
) {
  const cropKey = `SIZE_${cropWidth}x${cropHeight}`
  return fetchPost(
    `${CONTEXTPATH}/creatives/upload?by=URL&crop=${cropKey}`,
    { url },
    adAccountId,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data = {
        ...CreativeHelper.Image.toAPI(response.data),
        imageUUID: uuid(),
      }
    }

    return response
  })
}

/**
 * 5.1.7 소재 복사
 */
function copyCreatives(adAccountId, targetAdGroupId, creativeIds) {
  return RequestLock.acquire({
    key: 'copyCreatives',
    executor: done =>
      fetchPost(
        `${CONTEXTPATH}/creatives/copy`,
        { adGroupId: targetAdGroupId, creativeIds },
        adAccountId
      ).finally(done),
  })
}

/**
 * 5.3 소재 정보 조회
 */
function fetchAdCreativeInfoById(adAccountId, id) {
  return fetchGet(`${CONTEXTPATH}/creatives/${id}`, adAccountId)
}

/**
 * leaf 소재 조회
 */
function fetchCreativeChildInfoById({ adAccountId, id }) {
  return fetchGet(`${CONTEXTPATH}/creatives/${id}?type=child`, adAccountId)
}

/**
 * 5.5 소재 수정 대상 조회
 */
function fetchAdCreativeModifyTargetInfoById(adAccountId, id) {
  return fetchGet(`${CONTEXTPATH}/creatives/${id}/modifyTarget`, adAccountId)
}

/**
 * 5.6 여러개의 소재 생성
 */
function createAdCreatives(adAccountId, body, sending = false) {
  return RequestLock.acquire({
    key: 'createAdCreatives',
    executor: done =>
      fetchPost(
        `${CONTEXTPATH}/creatives${sending ? '/send' : ''}`,
        body,
        adAccountId
      ).finally(() => {
        done()
      }),
  })
}

/**
 * 5.8 소재 수정
 */
function modifyAdCreative(adAccountId, id, formData, sending = false) {
  return fetchPut(
    `${CONTEXTPATH}/creatives/${id}${sending ? '/send' : ''}`,
    formData,
    adAccountId
  )
}

/**
 * 5.13 소재 심사 보류 사유 조회
 */
function fetchAdCreativeReviewDenyReasonsById(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/${id}/reviewDenyReasons`,
    adAccountId
  )
}

/**
 * 5.24 소재의 수정없이 심사 요청 가능 여부
 */
function isReviewableAdCreativeWithoutChange(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/${id}/reviewableWithoutChange`,
    adAccountId
  )
}

/**
 * 소재 불러오기 > 캠페인, 광고그룹 목록
 */
function fetchCreativeImportCampaignAdGroups(
  adAccountId,
  targetAdGroupId,
  query
) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/import?type=AD_GROUP&targetAdGroupId=${targetAdGroupId}&searchName=${query}`,
    adAccountId
  )
}

/**
 * 소재 불러오기 > 소재 목록
 */
function fetchCreativeImportCreativesByAdGroup(
  adAccountId,
  sourceAdGroupId,
  targetAdGroupId
) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/import?type=CREATIVE&sourceAdGroupId=${sourceAdGroupId}&targetAdGroupId=${targetAdGroupId}`,
    adAccountId
  )
}

function fetchOpinionProof(adAccountId, creativeId) {
  return fetchGet(`${CONTEXTPATH}/opinionProof/${creativeId}`, adAccountId)
}

function checkCreativeImageTransparencyByURL({ adAccountId, url }) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/images/checkTransparency?by=URL`,
    { url },
    adAccountId
  )
}

function getCreativeExpandableActionButtons(adAccountId) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/actionButtons?commonCodeGroupType=EXPANDABLE_ACTION_BUTTON`,
    adAccountId
  )
}

function validateChannelPermissionByCreative({ adAccountId, id }) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/${id}/validatePermission`,
    adAccountId
  )
}

/**
 * 소재 관리자정지 사유 조회
 */
function fetchCreativeSystemConfigHistories(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/${id}/systemConfigHistories?systemConfig=ADMIN_STOP,VOID,EXTERNAL_SERVICE_STOP`,
    adAccountId
  )
}

function getCreativeVideoDownloadInfoByClipLinkId({ adAccountId, clipLinkId }) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/video/${clipLinkId}/download`,
    adAccountId
  )
}

function uploadCreativeVideoBySignedUrlAndFileName({
  adAccountId,
  fileName,
  signedUrl,
  transcodeType,
}) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/upload/tenth?${queryString({
      fileName: encodeURIComponent(fileName),
      url: encodeURIComponent(signedUrl),
      transcodeType: encodeURIComponent(transcodeType),
    })}`,
    null,
    adAccountId
  )
}

function getCreativeVideoPresetsByCmpVideoPresets({
  adAccountId,
  cmpVideoPresets = [],
}) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/presets`,
    cmpVideoPresets,
    adAccountId
  )
}

function getPersonalMessageVariables({ adAccountId }) {
  return fetchGet(`${CONTEXTPATH}/assets/variables`, adAccountId)
}

/**
 * 카탈로그 소재 > Tracking URL > 매크로 불러오기
 */
function getTrackingUrlMacros({ adAccountId }) {
  return fetchGet(`${CONTEXTPATH}/creatives/trackingUrl/macros`, adAccountId)
}

/**
 * 소재 > 랜딩, 추적 URL > 매크로 불러오기 (상품별 추적은 getTrackingUrlMacros)
 */
function getUrlMacros({ adAccountId }) {
  return fetchGet(`${CONTEXTPATH}/url/macros`, adAccountId)
}

export default {
  uploadCreativeImages,
  uploadCreativeImagesNoConstraint,
  uploadMultipleAdCreativeImagesWithBase64,
  uploadCreativeImageBase64,
  uploadCreativeImage,
  getVideoUploadTenthPath,
  uploadCreativeVideoToTenth,
  getCreativeVideoInfoByFileName,
  getMessageCreativeVideoUploadUrlToKakaoTv,
  uploadMessageCreativeVideoToKakaoTv,
  uploadMessageCreativeVideoUrlToKakaoTv,
  requestMessageCreativeVideoEncoding,
  getMessageCreativeVideoEncodingProgressingInfo,
  getMessageCreativeVideoKakaoTvInfo,
  modifyMessageCreativeVideoInfo,
  getMessageCreativeVideoPreviewToken,
  uploadAdCreativeImageByURL,
  uploadAdCreativeImageAndCropByURL,
  fetchAdCreativeInfoById,
  fetchCreativeChildInfoById,
  fetchAdCreativeModifyTargetInfoById,
  fetchAdCreativeReviewDenyReasonsById,
  createAdCreatives,
  modifyAdCreative,
  isReviewableAdCreativeWithoutChange,
  copyCreatives,
  fetchOpinionProof,
  checkCreativeImageTransparencyByURL,
  getCreativeExpandableActionButtons,
  validateChannelPermissionByCreative,
  fetchCreativeSystemConfigHistories,
  getCreativeVideoDownloadInfoByClipLinkId,
  uploadCreativeVideoBySignedUrlAndFileName,
  getCreativeVideoPresetsByCmpVideoPresets,
  getPersonalMessageVariables,
  getTrackingUrlMacros,
  getUrlMacros,
  fetchCreativeImportCampaignAdGroups,
  fetchCreativeImportCreativesByAdGroup,
}
