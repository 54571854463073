import { keyMirror } from '../utils/utils'
import { List } from 'immutable'

export default {
  Type: keyMirror({
    KAKAO_TALK: null,
    KAKAO_STORY: null,
    KAKAO_APPS: null,
    DAUM: null,
    KAKAO_SERVICE: null,
    NETWORK: null,
    KAKAO_TV: null,
  }),

  Name: {
    KAKAO_TALK: '카카오톡',
    KAKAO_STORY: '카카오스토리',
    KAKAO_APPS: '카카오앱스',
    DAUM: '다음',
    KAKAO_SERVICE: '카카오서비스',
    NETWORK: '네트워크',
    KAKAO_TV: '카카오TV',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getOrderedValues(placements) {
    const seq = this.values()
    return placements.sort((a, b) => seq.indexOf(a) - seq.indexOf(b))
  },

  /**
   * ReportV2
   */
  reportFilterLayerValues() {
    return this.values().filter(
      v => ![this.Type.KAKAO_APPS, this.Type.KAKAO_TV].includes(v)
    )
  },

  SkanPlacementValues() {
    return [this.Type.KAKAO_TALK, this.Type.DAUM, this.Type.KAKAO_SERVICE]
  },

  toString(
    allAvailablePlacement = false,
    placements = List(),
    allAvailableLabel = '가능한 모든 지면'
  ) {
    return allAvailablePlacement
      ? allAvailableLabel
      : this.getOrderedValues(placements)
          .map(v => this.getName(v))
          .join(', ')
  },
}
