import { keyMirror } from '../../../../utils/utils'
import {
  checkEmpty,
  checkNotEmpty,
  validateInputLength,
} from '../../../../utils/regexUtils'
import { ScaleImageFitCenter } from '../../../../utils/imageUtils'
import CreativeUploadAssetPropertyEnum from '../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  BIZ_BOARD_MULTI_OBJET_SPEC,
  BIZ_BOARD_OBJET_SPEC,
  BIZ_BOARD_SINGLE_THUMBNAIL_SPEC,
  BIZ_BOARD_SPEC,
} from '../../../../utils/advertise/creativeImage'
import BizBoardSubTypeEnum from '../../../../enums/BizBoardSubTypeEnum'

const BIZ_BOARD_BANNER_TYPE = keyMirror({
  OBJECT: null,
  THUMBNAIL: null,
  TEXT: null,
  MASKING: null,
})

const BIZ_BOARD_MASK_TYPE = keyMirror({
  NONE: null,
  SQUARE: null,
  BLUR: null,
  MULTI_IMAGE: null,
  SEMICIRCLE_TOP: null,
  SEMICIRCLE_DOWN: null,
  CYLINDER_LEFT: null,
  CYLINDER_RIGHT: null,
})

const BIZ_BOARD_NOTICE_TYPE = keyMirror({
  NONE: null,
  LEGAL: null,
  TALK_LANDING: null,
})

const BIZ_BOARD_COPY_TYPE = keyMirror({
  SINGLE: null,
  MULTI: null,
})

const BIZ_BOARD_APP_DOWNLOAD_TYPE = keyMirror({
  NONE: null,
  USE: null,
})

const BIZ_BOARD_IMAGE_TYPE = keyMirror({
  OBJET: null,
  LOGO: null,
})

const BIZ_BOARD_BADGE_TYPE = keyMirror({
  FLAG: null,
  BELT: null,
})

const BIZ_BOARD_COPY_DUPLICATION_TEXT =
  '메인 카피와 서브 카피는 서로 다른 문구를 입력하세요.'

/**
 * EXPRESS
 *
 * 템플릿: 오브젝트/썸네일/마스킹/텍스트 : O/M/K/T
 * 마스크유형: 없음/스퀘어/블러/반원형(상)/반원형(하)/원기둥형(좌)/원기둥형(우) (버퍼로 두자리) : 00/01/02/03/02/01/03/04
 * 텍스트유형: 한줄형/두줄형 : 1/2
 * 고지문유형: 없음/법적고지문/체팅방 랜딩 고지 : 0/1/2
 * 앱다운로드형: 없음/앱다운로드형 : 0/1
 *
 * ex)
 *  M01210: 썸네일 / 스퀘어 / 두줄 / 법적고지
 *  O00220: 오브젝트 / 두줄 / 체팅방 랜딩 고지
 */
const TEMPLATE_CODE = {
  BANNER_TYPE: {
    [BIZ_BOARD_BANNER_TYPE.OBJECT]: 'O',
    [BIZ_BOARD_BANNER_TYPE.THUMBNAIL]: 'M',
    [BIZ_BOARD_BANNER_TYPE.MASKING]: 'K',
    [BIZ_BOARD_BANNER_TYPE.TEXT]: 'T',
  },
  MASK_TYPE: {
    [BIZ_BOARD_MASK_TYPE.NONE]: '00',
    [BIZ_BOARD_MASK_TYPE.SQUARE]: '01',
    [BIZ_BOARD_MASK_TYPE.BLUR]: '02',
    [BIZ_BOARD_MASK_TYPE.MULTI_IMAGE]: '03',
    [BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN]: '01',
    [BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP]: '02',
    [BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT]: '03',
    [BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT]: '04',
  },
  COPY_TYPE: {
    [BIZ_BOARD_COPY_TYPE.SINGLE]: '1',
    [BIZ_BOARD_COPY_TYPE.MULTI]: '2',
  },
  NOTICE_TYPE: {
    [BIZ_BOARD_NOTICE_TYPE.NONE]: '0',
    [BIZ_BOARD_NOTICE_TYPE.LEGAL]: '1',
    [BIZ_BOARD_NOTICE_TYPE.TALK_LANDING]: '2',
  },
  APP_DOWNLOAD_TYPE: {
    [BIZ_BOARD_APP_DOWNLOAD_TYPE.NONE]: '0',
    [BIZ_BOARD_APP_DOWNLOAD_TYPE.USE]: '1',
  },
}

const BizBoardTemplateUtil = {
  initialize({ templateId }) {
    return {
      bannerType: this.getBannerType(templateId),
      maskType: this.getMaskType(templateId) || BIZ_BOARD_MASK_TYPE.NONE,
      copyType: this.getCopyType(templateId) || BIZ_BOARD_COPY_TYPE.SINGLE,
      noticeType: this.getNoticeType(templateId) || BIZ_BOARD_NOTICE_TYPE.NONE,
      appDownloadType:
        this.getAppDownloadType(templateId) || BIZ_BOARD_APP_DOWNLOAD_TYPE.NONE,
      imageIndex: -1,
      imageTypeArray: [],
      catalogProduct: null,
    }
  },

  findKey(value, obj) {
    for (const key in obj) {
      if (
        Object.prototype.hasOwnProperty.call(obj, key) &&
        obj[key] === value
      ) {
        return key
      }
    }
    return undefined
  },
  getBannerType(templateId) {
    return this.findKey(
      String(templateId).substr(0, 1),
      TEMPLATE_CODE.BANNER_TYPE
    )
  },
  getMaskType(templateId) {
    const maskTypeCode = String(templateId).substr(0, 3)
    switch (maskTypeCode) {
      case 'M01':
        return BIZ_BOARD_MASK_TYPE.SQUARE
      case 'M02':
        return BIZ_BOARD_MASK_TYPE.BLUR
      case 'M03':
        return BIZ_BOARD_MASK_TYPE.MULTI_IMAGE
      case 'K01':
        return BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN
      case 'K02':
        return BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP
      case 'K03':
        return BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT
      case 'K04':
        return BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT
      default:
        return BIZ_BOARD_MASK_TYPE.NONE
    }
  },
  getCopyType(templateId) {
    return this.findKey(
      String(templateId).substr(3, 1),
      TEMPLATE_CODE.COPY_TYPE
    )
  },
  getNoticeType(templateId) {
    return this.findKey(
      String(templateId).substr(4, 1),
      TEMPLATE_CODE.NOTICE_TYPE
    )
  },
  getAppDownloadType(templateId) {
    return this.findKey(
      String(templateId).substr(5, 1),
      TEMPLATE_CODE.APP_DOWNLOAD_TYPE
    )
  },
  getImageTypeArray(bannerType, maskType) {
    const imageTypeArray = []

    if (bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT) {
      imageTypeArray.push(BIZ_BOARD_IMAGE_TYPE.OBJET)
    }

    switch (maskType) {
      case BIZ_BOARD_MASK_TYPE.SQUARE:
      case BIZ_BOARD_MASK_TYPE.BLUR: {
        imageTypeArray.push(BIZ_BOARD_IMAGE_TYPE.OBJET)
        break
      }

      case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE: {
        imageTypeArray.push(
          BIZ_BOARD_IMAGE_TYPE.OBJET,
          BIZ_BOARD_IMAGE_TYPE.OBJET
        )
        break
      }

      case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP:
      case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN:
      case BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT:
      case BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT: {
        imageTypeArray.push(
          BIZ_BOARD_IMAGE_TYPE.OBJET,
          BIZ_BOARD_IMAGE_TYPE.LOGO
        )
        break
      }

      default: {
        break
      }
    }

    return imageTypeArray
  },
  create({ bannerType, maskType, copyType, noticeType, appDownloadType }) {
    const templateCodeArray = []

    switch (bannerType) {
      case BIZ_BOARD_BANNER_TYPE.OBJECT: {
        templateCodeArray.push(
          TEMPLATE_CODE.BANNER_TYPE.OBJECT,
          TEMPLATE_CODE.MASK_TYPE.NONE,
          TEMPLATE_CODE.COPY_TYPE[copyType],
          TEMPLATE_CODE.NOTICE_TYPE[noticeType],
          TEMPLATE_CODE.APP_DOWNLOAD_TYPE[appDownloadType]
        )
        break
      }

      case BIZ_BOARD_BANNER_TYPE.THUMBNAIL: {
        templateCodeArray.push(
          TEMPLATE_CODE.BANNER_TYPE.THUMBNAIL,
          TEMPLATE_CODE.MASK_TYPE[maskType],
          TEMPLATE_CODE.COPY_TYPE[copyType],
          TEMPLATE_CODE.NOTICE_TYPE[noticeType],
          TEMPLATE_CODE.APP_DOWNLOAD_TYPE[appDownloadType]
        )
        break
      }

      case BIZ_BOARD_BANNER_TYPE.MASKING: {
        templateCodeArray.push(
          TEMPLATE_CODE.BANNER_TYPE.MASKING,
          TEMPLATE_CODE.MASK_TYPE[maskType],
          TEMPLATE_CODE.COPY_TYPE[copyType],
          TEMPLATE_CODE.NOTICE_TYPE[noticeType],
          TEMPLATE_CODE.APP_DOWNLOAD_TYPE[appDownloadType]
        )
        break
      }

      case BIZ_BOARD_BANNER_TYPE.TEXT: {
        templateCodeArray.push(
          TEMPLATE_CODE.BANNER_TYPE.TEXT,
          TEMPLATE_CODE.MASK_TYPE.NONE,
          TEMPLATE_CODE.COPY_TYPE[copyType],
          TEMPLATE_CODE.NOTICE_TYPE[noticeType],
          TEMPLATE_CODE.APP_DOWNLOAD_TYPE.NONE
        )
        break
      }

      default:
        return undefined
    }

    const result = templateCodeArray.filter(Boolean)

    if (result.length === Object.keys(TEMPLATE_CODE).length) {
      return result.join('')
    }

    return undefined
  },
  getCreativeAssetType({ templateData }) {
    const { bannerType, maskType } = templateData || {}

    switch (bannerType) {
      case BIZ_BOARD_BANNER_TYPE.OBJECT:
        return CreativeUploadAssetPropertyEnum.Type.OBJET_IMAGE
      case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
        return maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE
          ? CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE_MULTI
          : maskType === BIZ_BOARD_MASK_TYPE.BLUR
          ? CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE_BLUR
          : CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE
      case BIZ_BOARD_BANNER_TYPE.MASKING:
        return CreativeUploadAssetPropertyEnum.Type.MASKING_IMAGE
      default:
        return undefined
    }
  },
}

const BIZ_BOARD_FORM_VALIDATION_KEY = keyMirror({
  COPY: null,
  MAIN_COPY: null,
  SUB_COPY: null,
  OBJET_IMAGE1: null,
  OBJET_IMAGE2: null,
  FULL_IMAGE_1: null,
  FULL_IMAGE_2: null,
  APP_ICON_IMAGE: null,
  APP_DOWNLOAD_COPY: null,
  NOTICE: null,
  LOGO: null,
  MASK_TYPE: null,
  BADGE_COLOR: null,
  BADGE_DESCRIPTION_1: null,
  BADGE_DESCRIPTION_2: null,
})

const BizBoardFormUtil = {
  /**
   * templateId,
   * title, // main copy
   * subtitle,  // app download copy
   * description, // sub copy
   * images: [],
   * icon,  // app icon image
   * badge,
   * notice,
   * logo,
   */
  initialize({ expressMetaInfo, boardType }) {
    const { templateId, expressMetaInfoAsset } = expressMetaInfo || {}

    const {
      title,
      subtitle,
      description,
      badge,
      badgeColor,
      badgeDescription,
      badgeDescription2,
      icon = null,
      useTalkIcon = false,
    } = expressMetaInfoAsset || {}

    const suitableTitle = title?.length >= 30 ? title.substring(0, 30) : title
    const suitableSubtitle =
      subtitle?.length >= 30
        ? subtitle.substring(0, 30)
        : checkNotEmpty(subtitle)
        ? subtitle
        : ''
    const suitableDescription =
      description?.length >= 30 ? description.substring(0, 30) : description
    const isBadgeAvailable =
      checkNotEmpty(BIZ_BOARD_BADGE_TYPE[badge]) &&
      !BizBoardSubTypeEnum.isSpecialBizBoard(boardType)
    const suitableBadge = isBadgeAvailable ? badge : null
    const suitableBadgeColor = isBadgeAvailable ? badgeColor : null
    const suitableBadgeDescription = isBadgeAvailable ? badgeDescription : ''
    const suitableBadgeDescription2 = isBadgeAvailable ? badgeDescription2 : ''

    return templateId && expressMetaInfoAsset
      ? {
          ...expressMetaInfoAsset,
          templateId,
          images: expressMetaInfoAsset.images?.map(v => v.asset) || [],
          title: suitableTitle,
          subtitle: suitableSubtitle,
          badge: suitableBadge,
          badgeColor: suitableBadgeColor,
          badgeDescription: suitableBadgeDescription,
          badgeDescription2: suitableBadgeDescription2,
          description: suitableDescription,
          useTalkIcon,
          icon,
        }
      : {
          templateId: '',
          title: '',
          subtitle: '',
          description: '',
          images: [],
          icon: null,
          useTalkIcon: false,
          notice: '',
          badge: null,
          badgeColor: '',
          badgeDescription: '',
          badgeDescription2: '',
        }
  },
  toExpressMetaInfo({
    templateId,
    formData,
    templateData,
    imageRects,
    imageScales,
  }) {
    return {
      templateId,
      expressMetaInfoAsset: {
        title: formData.title?.trim(),
        description:
          templateData.copyType === BIZ_BOARD_COPY_TYPE.MULTI
            ? formData.description?.trim()
            : undefined,
        images:
          templateData.bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT
            ? formData.images.map((image, index) => ({
                asset: image,
                extra: JSON.stringify({
                  imageRect: imageRects.get(index)?.toJS(),
                  imageScale: imageScales.get(index)?.toJS(),
                }),
              }))
            : undefined,
        icon:
          templateData.appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE
            ? formData.icon
            : undefined,
        subtitle:
          templateData.appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE
            ? formData.subtitle
            : undefined,
        notice:
          templateData.noticeType !== BIZ_BOARD_NOTICE_TYPE.NONE
            ? formData.notice
            : undefined,
        logo:
          templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING
            ? {
                asset: formData.images[1],
                extra: JSON.stringify({
                  imageRect: imageRects.get(1)?.toJS(),
                  imageScale: imageScales.get(1)?.toJS(),
                }),
              }
            : undefined,
        catalogProduct: templateData.catalogProduct?.toJS(),
        badge: checkNotEmpty(formData.badge) ? formData.badge : undefined,
        badgeColor: checkNotEmpty(formData.badge)
          ? formData.badgeColor
          : undefined,
        badgeDescription: checkNotEmpty(formData.badge)
          ? formData.badgeDescription
          : undefined,
        badgeDescription2: checkNotEmpty(formData.badge)
          ? formData.badgeDescription2
          : undefined,
        useTalkIcon: formData.useTalkIcon,
      },
    }
  },
  getImages: (templateData, formData, imageRects) => {
    return templateData.imageTypeArray
      .reduce((images, type, i) => {
        if (type === BIZ_BOARD_IMAGE_TYPE.OBJET)
          images.push({
            url: formData.images[i]?.url,
            ...(imageRects.get(i)?.toJS() || {}),
          })
        return images
      }, [])
      .map(image => ({
        ...image,
        url: image.url?.replace(/\/\/t1|\/\/bz/g, '//twg'),
      }))
  },
  toPreviewAPI({ templateId, formData, templateData, imageRects }) {
    return {
      id: templateId,
      title: formData.title || '메인 카피를 입력하세요.',
      subtitle: formData.subtitle || '앱 아이콘 카피를 입력하세요',
      images: this.getImages(templateData, formData, imageRects),
      icon: formData.icon,
      description: formData.description || '서브 카피를 입력하세요',
      notice:
        formData.notice ||
        (templateData.noticeType === BIZ_BOARD_NOTICE_TYPE.LEGAL
          ? '법적 고지문을 입력하세요'
          : ''),
      logo:
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
        formData.images[1]?.url
          ? {
              url: formData.images[1]?.url,
              ...(imageRects.get(1)?.toJS() || {}),
            }
          : {},
      badge: checkNotEmpty(formData.badge) ? formData.badge : undefined,
      badgeColor: checkNotEmpty(formData.badge)
        ? formData.badgeColor
        : undefined,
      badgeDescription: checkNotEmpty(formData.badge)
        ? checkNotEmpty(formData.badgeDescription)
          ? formData.badgeDescription
          : checkNotEmpty(formData.badgeDescription2)
          ? ''
          : '문구'
        : undefined,
      badgeDescription2: checkNotEmpty(formData.badge)
        ? formData.badgeDescription2
        : undefined,
      useTalkIcon: formData.useTalkIcon,
    }
  },
  toBannerAPI({ templateId, formData, templateData, imageRects }) {
    return {
      id: templateId,
      title: formData.title,
      subtitle: formData.subtitle,
      images: this.getImages(templateData, formData, imageRects),
      icon: formData.icon,
      description: formData.description,
      notice: formData.notice,
      logo:
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
        formData.images[1]?.url
          ? {
              url: formData.images[1]?.url,
              ...(imageRects.get(1)?.toJS() || {}),
            }
          : {},
      badge: checkNotEmpty(formData.badge) ? formData.badge : undefined,
      badgeColor: checkNotEmpty(formData.badge)
        ? formData.badgeColor
        : undefined,
      badgeDescription: checkNotEmpty(formData.badge)
        ? formData.badgeDescription
        : undefined,
      badgeDescription2: checkNotEmpty(formData.badge)
        ? formData.badgeDescription2
        : undefined,
      useTalkIcon: formData.useTalkIcon,
    }
  },
  validate({ formData, templateData, invalidations }) {
    const { bannerType, maskType, copyType, appDownloadType, noticeType } =
      templateData
    const expressValidations = invalidations.reduce(
      (acr, { errorCode, message }) => {
        switch (errorCode) {
          case 'MAIN_COPY_MAX_LENGTH':
          case 'SUB_COPY_MAX_LENGTH':
          case 'COPY_MAX_LENGTH':
          case 'COPY_MIN_LENGTH':
            return {
              ...acr,
              [BIZ_BOARD_FORM_VALIDATION_KEY.COPY]: { message, errorCode },
            }
          case 'FULL_IMAGE_1':
            return {
              ...acr,
              [BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_1]: {
                message,
                errorCode,
              },
            }
          case 'FULL_IMAGE_2':
            return {
              ...acr,
              [BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_2]: {
                message,
                errorCode,
              },
            }
          case 'BADGE_DESCRIPTION_1':
            return {
              ...acr,
              [BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_DESCRIPTION_1]: {
                message,
                errorCode,
              },
            }
          case 'BADGE_DESCRIPTION_2':
            return {
              ...acr,
              [BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_DESCRIPTION_2]: {
                message,
                errorCode,
              },
            }
          default:
            return { ...acr }
        }
      },
      {}
    )
    const {
      title: mainCopy,
      subtitle: appDownloadCopy,
      description: subCopy,
      images: objetImages,
      icon: appIconImage,
      badge,
      badgeColor,
      badgeDescription,
      notice,
      useTalkIcon,
    } = formData

    const isMainCopyNotExist = !validateInputLength(
      String(mainCopy).trim(),
      1,
      50
    )

    const isSubCopyNotExist =
      copyType === BIZ_BOARD_COPY_TYPE.MULTI &&
      !validateInputLength(String(subCopy).trim(), 1, 50)

    const invalidObjetImage1 =
      bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT &&
      (!objetImages || checkEmpty(objetImages[0]?.url))

    const invalidObjetImage2 =
      maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE &&
      (!objetImages || checkEmpty(objetImages[1]?.url))

    const invalidLogo =
      bannerType === BIZ_BOARD_BANNER_TYPE.MASKING &&
      (!objetImages || checkEmpty(objetImages[1]?.url))

    const useAppDownload = appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE
    const invalidAppIconImage =
      useAppDownload && !useTalkIcon && checkEmpty(appIconImage?.url)

    const invalidBadgeColor = checkNotEmpty(badge) && checkEmpty(badgeColor)
    const invalidBadgeDescription =
      checkNotEmpty(badge) && checkEmpty(badgeDescription)

    const invalidAppDownloadCopy1 =
      useAppDownload &&
      !validateInputLength(String(appDownloadCopy).trim(), 1, 50)

    const invalidNotice1 =
      noticeType === BIZ_BOARD_NOTICE_TYPE.LEGAL &&
      !validateInputLength(String(notice).trim(), 1, 50)

    const invalidNotice2 =
      noticeType === BIZ_BOARD_NOTICE_TYPE.TALK_LANDING &&
      !validateInputLength(String(notice).trim(), 0, 50)

    const copyDuplicate =
      checkNotEmpty(subCopy) &&
      String(mainCopy).trim() === String(subCopy).trim()

    const invalidMainCopy = isMainCopyNotExist || copyDuplicate

    const invalidSubCopy = isSubCopyNotExist || copyDuplicate

    const localValidationState = {
      [BIZ_BOARD_FORM_VALIDATION_KEY.MAIN_COPY]: invalidMainCopy
        ? {
            message: copyDuplicate
              ? BIZ_BOARD_COPY_DUPLICATION_TEXT
              : '메인 카피를 입력하세요.',
          }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.SUB_COPY]: invalidSubCopy
        ? {
            message: copyDuplicate
              ? BIZ_BOARD_COPY_DUPLICATION_TEXT
              : '서브 카피를 입력하세요.',
          }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.OBJET_IMAGE1]: invalidObjetImage1
        ? { message: '이미지를 업로드 하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.OBJET_IMAGE2]: invalidObjetImage2
        ? { message: '이미지를 업로드 하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.LOGO]: invalidLogo
        ? { message: '로고를 업로드 하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.APP_ICON_IMAGE]: invalidAppIconImage
        ? { message: '이미지를 업로드 하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_COLOR]: invalidBadgeColor
        ? { message: '색상을 선택하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.BADGE_DESCRIPTION_1]:
        invalidBadgeDescription
          ? {
              message:
                badge === BIZ_BOARD_BADGE_TYPE.FLAG
                  ? '한 줄 배지 문구를 입력하세요.'
                  : '배지 문구를 입력하세요.',
            }
          : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.APP_DOWNLOAD_COPY]: invalidAppDownloadCopy1
        ? { message: '앱 아이콘 카피를 입력하세요.' }
        : undefined,
      [BIZ_BOARD_FORM_VALIDATION_KEY.NOTICE]:
        invalidNotice1 || invalidNotice2
          ? { message: '고지문을 입력하세요.' }
          : undefined,
    }

    const validationState = { ...localValidationState, ...expressValidations }
    return {
      isValid: Object.values(validationState).every(v => v === undefined),
      validationState,
    }
  },
  isCompleted({ formData, templateData }) {
    const { title: mainCopy, description: subCopy } = formData
    return (
      formData.title?.length > 0 &&
      (templateData.bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT ||
      templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
      templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING
        ? formData.images?.filter(Boolean).length ===
          templateData.imageTypeArray.length
        : true) &&
      !(checkNotEmpty(subCopy) && mainCopy === subCopy)
    )
  },
  isNotSupportedMaskType({ templateData }) {
    const { maskType } = templateData
    return (
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT ||
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT
    )
  },
  getFailureImages({ formData, templateData }) {
    const failureImages = []
    const { images: objectImages, icon: appIconImage } = formData
    const { bannerType, maskType } = templateData

    objectImages.map((image, idx) => {
      const invalidReasons = []
      const { imageHeight, imageWidth, mimeType, fileSize, originalFileName } =
        image || {}
      switch (bannerType) {
        case BIZ_BOARD_BANNER_TYPE.OBJECT:
          if (imageHeight !== 258 || imageWidth !== 315) {
            const reason = IMAGE_FAILURE_REASON_ENUM.IMAGE_SIZE
            invalidReasons.push({
              reason,
              description: IMAGE_FAILURE_REASON_TEXT[reason],
            })
          }
          if (mimeType !== 'image/png') {
            const reason = IMAGE_FAILURE_REASON_ENUM.FORMAT
            invalidReasons.push({
              reason,
              description: IMAGE_FAILURE_REASON_TEXT[reason],
            })
          }
          if (fileSize > 150 * 1000) {
            const reason = IMAGE_FAILURE_REASON_ENUM.FILE_SIZE
            invalidReasons.push({
              reason,
              description: IMAGE_FAILURE_REASON_TEXT[reason],
            })
          }
          break

        case BIZ_BOARD_BANNER_TYPE.MASKING:
          if (idx === 0) {
            if (imageHeight < 258 || imageWidth < 315) {
              const reason = IMAGE_FAILURE_REASON_ENUM.IMAGE_SIZE
              invalidReasons.push({
                reason,
                description: IMAGE_FAILURE_REASON_TEXT[reason],
              })
            }
            if (
              mimeType !== 'image/png' &&
              mimeType !== 'image/jpg' &&
              mimeType !== 'image/jpeg'
            ) {
              const reason = IMAGE_FAILURE_REASON_ENUM.FORMAT
              invalidReasons.push({
                reason,
                description: IMAGE_FAILURE_REASON_TEXT[reason],
              })
            }
            if (fileSize > 10 * 1000 * 1000) {
              const reason = IMAGE_FAILURE_REASON_ENUM.FILE_SIZE
              invalidReasons.push({
                reason,
                description: IMAGE_FAILURE_REASON_TEXT[reason],
              })
            }
          }
          break

        case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
          if (maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE) {
            if (
              imageHeight < BIZ_BOARD_MULTI_OBJET_SPEC.HEIGHT ||
              BIZ_BOARD_SINGLE_THUMBNAIL_SPEC.WIDTH < 172
            ) {
              const reason = IMAGE_FAILURE_REASON_ENUM.IMAGE_SIZE
              invalidReasons.push({
                reason,
                description: IMAGE_FAILURE_REASON_TEXT[reason],
              })
            }
          } else {
            if (
              imageHeight < BIZ_BOARD_SINGLE_THUMBNAIL_SPEC.HEIGHT ||
              imageWidth < BIZ_BOARD_SINGLE_THUMBNAIL_SPEC.WIDTH
            ) {
              const reason = IMAGE_FAILURE_REASON_ENUM.IMAGE_SIZE
              invalidReasons.push({
                reason,
                description: IMAGE_FAILURE_REASON_TEXT[reason],
              })
            }
          }
          if (
            mimeType !== 'image/png' &&
            mimeType !== 'image/jpg' &&
            mimeType !== 'image/jpeg'
          ) {
            const reason = IMAGE_FAILURE_REASON_ENUM.FORMAT
            invalidReasons.push({
              reason,
              description: IMAGE_FAILURE_REASON_TEXT[reason],
            })
          }
          if (fileSize > 10 * 1000 * 1000) {
            const reason = IMAGE_FAILURE_REASON_ENUM.FILE_SIZE
            invalidReasons.push({
              reason,
              description: IMAGE_FAILURE_REASON_TEXT[reason],
            })
          }
          break

        case BIZ_BOARD_BANNER_TYPE.TEXT:
        default:
          break
      }
      if (invalidReasons.length > 0) {
        failureImages.push({ fileName: originalFileName, invalidReasons })
      }
    })

    const {
      url,
      imageHeight,
      imageWidth,
      mimeType,
      fileSize,
      originalFileName,
    } = appIconImage || {}

    if (checkNotEmpty(url)) {
      const invalidReasons = []
      if (imageHeight !== 38 || imageWidth !== 38) {
        const reason = IMAGE_FAILURE_REASON_ENUM.IMAGE_SIZE
        invalidReasons.push({
          reason,
          description: IMAGE_FAILURE_REASON_TEXT[reason],
        })
      }
      if (imageHeight / imageWidth !== 1) {
        const reason = IMAGE_FAILURE_REASON_ENUM.DIMENSION
        invalidReasons.push({
          reason,
          description: IMAGE_FAILURE_REASON_TEXT[reason],
        })
      }
      if (mimeType !== 'image/png') {
        const reason = IMAGE_FAILURE_REASON_ENUM.FORMAT
        invalidReasons.push({
          reason,
          description: IMAGE_FAILURE_REASON_TEXT[reason],
        })
      }
      if (fileSize > 80 * 1000) {
        const reason = IMAGE_FAILURE_REASON_ENUM.FILE_SIZE
        invalidReasons.push({
          reason,
          description: IMAGE_FAILURE_REASON_TEXT[reason],
        })
      }

      if (invalidReasons.length > 0) {
        failureImages.push({ fileName: originalFileName, invalidReasons })
      }
    }

    return failureImages
  },
}

const INTRINSIC_BANNER_PADDING_H = 47.2 // 배너 좌우 실측 padding
const INTRINSIC_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH = 315 // 썸네일 단일이미지 박스형 실측 width
const INTRINSIC_OBJET_IMAGE_WIDTH = 360 // 나머지 오브제 이미지 실측 width

const VIEW_BANNER_WIDTH = 638
const RATIO = BIZ_BOARD_SPEC.WIDTH / VIEW_BANNER_WIDTH
const VIEW_BANNER_HEIGHT = Math.ceil(BIZ_BOARD_SPEC.HEIGHT / RATIO)
const VIEW_BANNER_PADDING_H = INTRINSIC_BANNER_PADDING_H / RATIO
const VIEW_OBJET_IMAGE_WIDTH = INTRINSIC_OBJET_IMAGE_WIDTH / RATIO
const VIEW_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH =
  INTRINSIC_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH / RATIO

const INTRINSIC_LOGO_WIDTH = 142 // 로고 width
const INTRINSIC_LOGO_HEIGHT = 60 // 로고 height
const INTRINSIC_BANNER_PADDING_V = 16 // 로고 위아래 실측 padding

const VIEW_LOGO_WIDTH = INTRINSIC_LOGO_WIDTH / RATIO
const VIEW_LOGO_HEIGHT = INTRINSIC_LOGO_HEIGHT / RATIO
const VIEW_LOGO_PADDING_V = INTRINSIC_BANNER_PADDING_V / RATIO

const VIEW_LOGO_IMAGE_SIZE = {
  width: VIEW_LOGO_WIDTH,
  height: VIEW_LOGO_HEIGHT,
}

const BizBoardImageSpec = {
  getIntrinsicCopyMinimum({ bannerType, useBadge }) {
    switch (bannerType) {
      case BIZ_BOARD_BANNER_TYPE.OBJECT:
      case BIZ_BOARD_BANNER_TYPE.THUMBNAIL:
      case BIZ_BOARD_BANNER_TYPE.MASKING: {
        if (useBadge) {
          return 370
        }

        return 290
      }

      case BIZ_BOARD_BANNER_TYPE.TEXT: {
        return 480
      }

      default: {
        break
      }
    }

    return 0
  },
  getImageSpec: ({ maskType }) => {
    switch (maskType) {
      case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE:
        return BIZ_BOARD_MULTI_OBJET_SPEC
      case BIZ_BOARD_MASK_TYPE.SQUARE:
        return BIZ_BOARD_SINGLE_THUMBNAIL_SPEC
      default:
        return BIZ_BOARD_OBJET_SPEC
    }
  },
  INTRINSIC_BANNER_WIDTH: BIZ_BOARD_SPEC.WIDTH,
  INTRINSIC_BANNER_HEIGHT: BIZ_BOARD_SPEC.HEIGHT,
  INTRINSIC_BANNER_PADDING_H,
  INTRINSIC_OBJET_IMAGE_WIDTH,
  INTRINSIC_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH,
  VIEW_BANNER_WIDTH,
  VIEW_BANNER_HEIGHT,
  VIEW_BANNER_PADDING_H,
  VIEW_OBJET_IMAGE_WIDTH,
  VIEW_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH,
  RATIO,
}

const BizBoardImageEditUtil = {
  getImageSize({ maskType }) {
    if (
      maskType === BIZ_BOARD_MASK_TYPE.SQUARE ||
      maskType === BIZ_BOARD_MASK_TYPE.BLUR
    ) {
      return 1
    } else if (
      maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE ||
      maskType === BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP ||
      maskType === BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN ||
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT ||
      maskType === BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT
    ) {
      return 2
    }
    return 0
  },

  getBackgroundLayerRect({ bannerType, maskType }) {
    const OBJET_WIDTH =
      bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
      maskType === BIZ_BOARD_MASK_TYPE.SQUARE
        ? VIEW_OBJET_THUMBNAIL_SINGLE_SQUARE_IMAGE_WIDTH
        : VIEW_OBJET_IMAGE_WIDTH
    return {
      top: 0,
      left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - OBJET_WIDTH,
      width: OBJET_WIDTH,
      height: VIEW_BANNER_HEIGHT,
    }
  },

  getAppIconGuideTop({ appDownloadType, copyType, noticeType }) {
    if (
      appDownloadType === BIZ_BOARD_APP_DOWNLOAD_TYPE.USE &&
      noticeType !== BIZ_BOARD_NOTICE_TYPE.NONE
    ) {
      return 32
    }

    if (copyType === BIZ_BOARD_COPY_TYPE.SINGLE) {
      return 44
    } else {
      return 26
    }
  },

  getDragLayerRect({ maskType, editImageIndex = 0 }) {
    switch (maskType) {
      case BIZ_BOARD_MASK_TYPE.SQUARE: {
        const intrinsicWidth = 315
        const intrinsicHeight = 186
        const intrinsicPaddingV = 36
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingV = intrinsicPaddingV / RATIO

        return {
          top: paddingV,
          left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
          width,
          height,
        }
      }

      case BIZ_BOARD_MASK_TYPE.BLUR: {
        const intrinsicWidth = 360
        const intrinsicHeight = 246
        const intrinsicPaddingTop = 15
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingTop = intrinsicPaddingTop / RATIO

        return {
          top: paddingTop,
          left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
          width,
          height,
        }
      }

      case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE: {
        const intrinsicWidth = 172
        const intrinsicHeight = 172
        const intrinsicPaddingV = 43
        const intrinsicPaddingO = 16
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingV = intrinsicPaddingV / RATIO
        const paddingO = intrinsicPaddingO / RATIO

        return {
          0: {
            top: paddingV,
            left:
              VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width * 2 - paddingO,
            width,
            height,
          },
          1: {
            top: paddingV,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width,
            height,
          },
        }[editImageIndex]
      }

      case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP: {
        const intrinsicWidth = 360
        const intrinsicHeight = 213
        const intrinsicPaddingTop = 0
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingTop = intrinsicPaddingTop / RATIO

        return {
          0: {
            top: paddingTop,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width,
            height,
          },
          1: {
            ...VIEW_LOGO_IMAGE_SIZE,
            top: VIEW_BANNER_HEIGHT - VIEW_LOGO_PADDING_V - VIEW_LOGO_HEIGHT,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - VIEW_LOGO_WIDTH,
          },
        }[editImageIndex]
      }

      case BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN: {
        const intrinsicWidth = 360
        const intrinsicHeight = 213
        const intrinsicPaddingTop = 46
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingTop = intrinsicPaddingTop / RATIO

        return {
          0: {
            top: paddingTop,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width,
            height,
          },
          1: {
            ...VIEW_LOGO_IMAGE_SIZE,
            top: VIEW_LOGO_PADDING_V,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - VIEW_LOGO_WIDTH,
          },
        }[editImageIndex]
      }

      /* deprecated */
      case BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT: {
        const intrinsicWidth = 360
        const intrinsicHeight = 226
        const intrinsicPaddingTop = 32
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingTop = intrinsicPaddingTop / RATIO

        return {
          0: {
            top: paddingTop,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width,
            height,
          },
          1: {
            ...VIEW_LOGO_IMAGE_SIZE,
            top: VIEW_LOGO_PADDING_V,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - VIEW_LOGO_WIDTH,
          },
        }[editImageIndex]
      }

      /* deprecated */
      case BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT: {
        const intrinsicWidth = 360
        const intrinsicHeight = 226
        const intrinsicPaddingTop = 32
        const width = intrinsicWidth / RATIO
        const height = intrinsicHeight / RATIO
        const paddingTop = intrinsicPaddingTop / RATIO

        return {
          0: {
            top: paddingTop,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width,
            height,
          },
          1: {
            top: VIEW_LOGO_PADDING_V,
            left: VIEW_BANNER_WIDTH - VIEW_BANNER_PADDING_H - width,
            width: VIEW_LOGO_WIDTH,
            height: VIEW_LOGO_HEIGHT,
          },
        }[editImageIndex]
      }

      default: {
        break
      }
    }

    return undefined
  },

  getImageNodePosition({
    imageWidth,
    imageHeight,
    dragLayerRect,
    imageType,
    isNotPositionedRelativeToBanner,
  }) {
    const {
      scaledWidth: fitCenterImageWidth,
      scaledHeight: fitCenterImageHeight,
    } = ScaleImageFitCenter(
      imageWidth,
      imageHeight,
      dragLayerRect.width,
      dragLayerRect.height
    )

    const scale =
      imageType === BIZ_BOARD_IMAGE_TYPE.LOGO
        ? 1
        : dragLayerRect.width > fitCenterImageWidth
        ? dragLayerRect.width / fitCenterImageWidth
        : dragLayerRect.height > fitCenterImageHeight
        ? dragLayerRect.height / fitCenterImageHeight
        : 1

    const imageLayerPaddingLeft = isNotPositionedRelativeToBanner
      ? 0
      : dragLayerRect.left

    const imageLayerPaddingTop = isNotPositionedRelativeToBanner
      ? 0
      : dragLayerRect.top

    const left =
      dragLayerRect.width > fitCenterImageWidth
        ? imageLayerPaddingLeft +
          (dragLayerRect.width - fitCenterImageWidth) / 2
        : fitCenterImageWidth > dragLayerRect.width
        ? imageLayerPaddingLeft -
          (fitCenterImageWidth - dragLayerRect.width) / 2
        : imageLayerPaddingLeft

    const top =
      dragLayerRect.height > fitCenterImageHeight
        ? imageLayerPaddingTop +
          (dragLayerRect.height - fitCenterImageHeight) / 2
        : fitCenterImageHeight > dragLayerRect.height
        ? imageLayerPaddingTop -
          (fitCenterImageHeight - dragLayerRect.height) / 2
        : imageLayerPaddingTop

    return {
      top,
      left,
      scale,
      fitCenterImageHeight,
    }
  },
}

const PAST_BIZ_BOARD_MASK_OPTIONS = [
  {
    id: BIZ_BOARD_MASK_TYPE.SQUARE,
    label: '박스',
    bannerType: BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
    imageCount: 1,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.BLUR,
    label: '블러',
    bannerType: BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
    imageCount: 1,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP,
    label: '반원형(상)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN,
    label: '반원형(하)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.CYLINDER_LEFT,
    label: '원기둥형(좌)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: false,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.CYLINDER_RIGHT,
    label: '원기둥형(우)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: false,
  },
]

const BIZ_BOARD_MASK_OPTIONS = [
  {
    id: BIZ_BOARD_MASK_TYPE.SQUARE,
    label: '박스',
    bannerType: BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
    imageCount: 1,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.BLUR,
    label: '블러',
    bannerType: BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
    imageCount: 1,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP,
    label: '반원형(상)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: true,
  },
  {
    id: BIZ_BOARD_MASK_TYPE.SEMICIRCLE_DOWN,
    label: '반원형(하)',
    bannerType: BIZ_BOARD_BANNER_TYPE.MASKING,
    imageCount: 2,
    available: true,
  },
]

const IMAGE_FAILURE_REASON_ENUM = keyMirror({
  FORMAT: null,
  IMAGE_SIZE: null,
  FILE_SIZE: null,
  DIMENSION: null,
})
const IMAGE_FAILURE_REASON_TEXT = {
  FORMAT: '저장 가능한 이미지 포맷이 아닙니다.',
  IMAGE_SIZE: '저장 가능한 이미지 사이즈가 아닙니다.',
  FILE_SIZE: '저장 가능한 이미지 용량이 아닙니다.',
  DIMENSION: '저장 가능한 이미지 비율이 아닙니다.',
}

// allowed: /u2192(→),\u2018(‘), \u2019(’), \u201C(“), \u201D(”)
const BIZBOARD_COPY_ALLOWED_CHAR = '\u2192\u2018\u2019\u201C\u201D'

export {
  BizBoardTemplateUtil,
  BizBoardFormUtil,
  BizBoardImageSpec,
  BizBoardImageEditUtil,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_MASK_TYPE,
  BIZ_BOARD_NOTICE_TYPE,
  BIZ_BOARD_COPY_TYPE,
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_IMAGE_TYPE,
  BIZ_BOARD_MASK_OPTIONS,
  PAST_BIZ_BOARD_MASK_OPTIONS,
  BIZBOARD_COPY_ALLOWED_CHAR,
}
