import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import DashboardMetricGroupEnumV2 from '../../enums/DashboardMetricGroupEnumV2'
import { DASHBOARD_CVR_SUFFIX_AVAILABLE_METRICS } from '../../components/DashboardV3/dashboardUtils'
import ObjectiveDetailTypeEnum from '../../enums/ObjectiveDetailTypeEnum'

const DashboardTableMetric = {
  COST: [{ id: 'cost', label: '비용' }],
  DEFAULT: [
    { id: 'imp', label: '노출수' },
    { id: 'click', label: '클릭수' },
    { id: 'ctr', label: '클릭률' },
    { id: 'reach', label: '도달수' },
    { id: 'video_play_3s', label: '재생수' },
    { id: 'vtr', label: '재생률' },
    { id: 'cost_per_imp', label: '노출당 비용' },
    { id: 'cost_per_click', label: '클릭당 비용' },
    { id: 'cost_per_reach', label: '도달당 비용' },
    { id: 'cost_per_video_play_3s', label: '재생당 비용' },
  ],
  MESSAGE_SENDING: [
    { id: 'msg_send', label: '발송수' },
    { id: 'msg_send_fail', label: '발송 실패수' },
    { id: 'msg_open', label: '열람수' },
    { id: 'msg_click', label: '전체 클릭수' },
    { id: 'msg_play', label: '전체 재생수' },
    { id: 'cost_per_msg_send', label: '발송당 비용' },
    { id: 'cost_per_msg_open', label: '열람당 비용' },
    { id: 'cost_per_msg_click', label: '전체 클릭당 비용' },
    { id: 'cost_per_msg_play', label: '전체 재생당 비용' },
    { id: 'msg_open_rate', label: '열람률' },
    { id: 'msg_click_rate', label: '메시지 클릭률' },
  ],
  PIXEL_AND_SDK: [
    { id: 'conv_cmpt_reg_1d', label: '회원가입 (1일)' },
    { id: 'conv_cmpt_reg_7d', label: '회원가입 (7일)' },
    { id: 'cost_per_conv_cmpt_reg_1d', label: '회원가입당 비용 (1일)' },
    { id: 'cost_per_conv_cmpt_reg_7d', label: '회원가입당 비용 (7일)' },
    { id: 'conv_cmpt_reg_1d_rate', label: '회원가입률 (1일)' },
    { id: 'conv_cmpt_reg_7d_rate', label: '회원가입률 (7일)' },
    { id: 'conv_view_cart_1d', label: '장바구니 열람 (1일)' },
    { id: 'conv_view_cart_7d', label: '장바구니 열람 (7일)' },
    { id: 'cost_per_conv_view_cart_1d', label: '장바구니 열람당 비용 (1일)' },
    { id: 'cost_per_conv_view_cart_7d', label: '장바구니 열람당 비용 (7일)' },
    { id: 'conv_view_cart_1d_rate', label: '장바구니 열람률 (1일)' },
    { id: 'conv_view_cart_7d_rate', label: '장바구니 열람률 (7일)' },
    { id: 'conv_purchase_1d', label: '구매 (1일)' },
    { id: 'conv_purchase_7d', label: '구매 (7일)' },
    { id: 'cost_per_conv_purchase_1d', label: '구매당 비용 (1일)' },
    { id: 'cost_per_conv_purchase_7d', label: '구매당 비용 (7일)' },
    { id: 'conv_purchase_1d_rate', label: '구매율 (1일)' },
    { id: 'conv_purchase_7d_rate', label: '구매율 (7일)' },
    { id: 'conv_purchase_p_1d', label: '구매금액 (1일)' },
    { id: 'conv_purchase_p_7d', label: '구매금액 (7일)' },
    { id: 'conv_purchase_p_per_cost_1d', label: 'ROAS (1일)' },
    { id: 'conv_purchase_p_per_cost_7d', label: 'ROAS (7일)' },
    { id: 'conv_estimated_purchase_p_per_cost_1d', label: '추정 ROAS (1일)' },
    { id: 'conv_estimated_purchase_p_per_cost_7d', label: '추정 ROAS (7일)' },
    { id: 'conv_participation_1d', label: '잠재고객 (1일)' },
    { id: 'conv_participation_7d', label: '잠재고객 (7일)' },
    { id: 'cost_per_conv_participation_1d', label: '잠재고객당 비용 (1일)' },
    { id: 'cost_per_conv_participation_7d', label: '잠재고객당 비용 (7일)' },
    { id: 'conv_participation_1d_rate', label: '잠재고객률 (1일)' },
    { id: 'conv_participation_7d_rate', label: '잠재고객률 (7일)' },
    { id: 'conv_signup_1d', label: '서비스 신청 (1일)' },
    { id: 'conv_signup_7d', label: '서비스 신청 (7일)' },
    { id: 'cost_per_conv_signup_1d', label: '서비스신청당 비용 (1일)' },
    { id: 'cost_per_conv_signup_7d', label: '서비스신청당 비용 (7일)' },
    { id: 'conv_signup_1d_rate', label: '서비스신청률 (1일)' },
    { id: 'conv_signup_7d_rate', label: '서비스신청률 (7일)' },
    { id: 'conv_app_install_1d', label: '앱설치 (1일)' },
    { id: 'conv_app_install_7d', label: '앱설치 (7일)' },
    { id: 'cost_per_conv_app_install_1d', label: '앱설치당 비용 (1일)' },
    { id: 'cost_per_conv_app_install_7d', label: '앱설치당 비용 (7일)' },
    { id: 'conv_app_install_1d_rate', label: '앱설치율 (1일)' },
    { id: 'conv_app_install_7d_rate', label: '앱설치율 (7일)' },
    { id: 'conv_add_to_wishlist_1d', label: '관심상품추가 (1일) ' },
    {
      id: 'cost_per_conv_add_to_wishlist_1d',
      label: '관심상품추가당 비용 (1일)',
    },
    { id: 'conv_add_to_wishlist_1d_rate', label: '관심상품추가율 (1일)' },
    { id: 'conv_add_to_wishlist_7d', label: '관심상품추가 (7일)' },
    {
      id: 'cost_per_conv_add_to_wishlist_7d',
      label: '관심상품추가당 비용 (7일)',
    },
    { id: 'conv_add_to_wishlist_7d_rate', label: '관심상품추가율 (7일) ' },
    { id: 'conv_add_to_cart_1d', label: '장바구니추가 (1일)' },
    { id: 'cost_per_conv_add_to_cart_1d', label: '장바구니추가당 비용 (1일)' },
    { id: 'conv_add_to_cart_1d_rate', label: '장바구니추가율 (1일) ' },
    { id: 'conv_add_to_cart_7d', label: '장바구니추가 (7일)' },
    { id: 'cost_per_conv_add_to_cart_7d', label: '장바구니추가당 비용 (7일)' },
    { id: 'conv_add_to_cart_7d_rate', label: '장바구니추가율 (7일)' },
  ],
  PLUS_FRIEND: [
    { id: 'conv_kf_pf_add_1d', label: '친구 추가수 (1일)' },
    { id: 'conv_kf_pf_add_7d', label: '친구 추가수 (7일)' },
    { id: 'cost_per_conv_kf_pf_add_1d', label: '친구 추가 수당 비용 (1일)' },
    { id: 'cost_per_conv_kf_pf_add_7d', label: '친구 추가 수당 비용 (7일)' },
    { id: 'conv_kf_pf_add_1d_rate', label: '친구 추가율 (1일)' },
    { id: 'conv_kf_pf_add_7d_rate', label: '친구 추가율 (7일)' },
  ],
  VIDEO: [
    { id: 'video_play_auto', label: '자동 재생수' },
    { id: 'video_play_touch', label: '터치 재생수' },
    { id: 'video_unmute', label: '사운드 ON수' },
    { id: 'video_play_5s', label: '5초 재생수' },
    { id: 'video_play_10s', label: '10초 재생수' },
    { id: 'video_play_15s', label: '15초 재생수' },
    { id: 'video_play_30s', label: '30초 재생수' },
    { id: 'video_play_60s', label: '60초 재생수' },
    { id: 'video_play_25p', label: '25% 재생수' },
    { id: 'video_play_50p', label: '50% 재생수' },
    { id: 'video_play_75p', label: '75% 재생수' },
    { id: 'video_play_100p', label: '100% 재생수' },
  ],
  CATALOG_SLIDE: [
    { id: 'slide1_click', label: '슬라이드1 클릭수' },
    { id: 'slide2_click', label: '슬라이드2 클릭수' },
    { id: 'slide3_click', label: '슬라이드3 클릭수' },
    { id: 'slide4_click', label: '슬라이드4 클릭수' },
    { id: 'slide5_click', label: '슬라이드5 클릭수' },
    { id: 'slide6_click', label: '슬라이드6 클릭수' },
    { id: 'slide7_click', label: '슬라이드7 클릭수' },
    { id: 'slide8_click', label: '슬라이드8 클릭수' },
    { id: 'slide9_click', label: '슬라이드9 클릭수' },
    { id: 'slide10_click', label: '슬라이드10 클릭수' },
    { id: 'slide_others_click', label: '기타 클릭수' },
  ],
  MESSAGE: [
    { id: 'msg_click_share', label: '공유 버튼 클릭수' },
    { id: 'msg_click_media', label: '이미지/동영상 클릭수' },
    { id: 'msg_click_text', label: '텍스트 클릭수' },
    { id: 'msg_click_title', label: '타이틀 클릭수' },
    { id: 'msg_play_start', label: '동영상 재생수' },
    { id: 'msg_play_3s', label: '동영상 3초 재생수' },
    { id: 'msg_play_5s', label: '동영상 5초 재생수' },
    { id: 'msg_play_10s', label: '동영상 10초 재생수' },
    { id: 'msg_play_15s', label: '동영상 15초 재생수' },
    { id: 'msg_play_30s', label: '동영상 30초 재생수' },
    { id: 'msg_play_60s', label: '동영상 60초 재생수' },
    { id: 'msg_click_list1', label: '리스트1 클릭수' },
    { id: 'msg_play_list1_start', label: '리스트1 재생수' },
    { id: 'msg_play_list1_3s', label: '리스트1 3초 재생수' },
    { id: 'msg_play_list1_5s', label: '리스트1 5초 재생수' },
    { id: 'msg_play_list1_10s', label: '리스트1 10초 재생수' },
    { id: 'msg_play_list1_15s', label: '리스트1 15초 재생수' },
    { id: 'msg_play_list1_30s', label: '리스트1 30초 재생수' },
    { id: 'msg_play_list1_60s', label: '리스트1 60초 재생수' },
    { id: 'msg_click_list2', label: '리스트2 클릭수' },
    { id: 'msg_play_list2_start', label: '리스트2 재생수' },
    { id: 'msg_play_list2_3s', label: '리스트2 3초 재생수' },
    { id: 'msg_play_list2_5s', label: '리스트2 5초 재생수' },
    { id: 'msg_play_list2_10s', label: '리스트2 10초 재생수' },
    { id: 'msg_play_list2_15s', label: '리스트2 15초 재생수' },
    { id: 'msg_play_list2_30s', label: '리스트2 30초 재생수' },
    { id: 'msg_play_list2_60s', label: '리스트2 60초 재생수' },
    { id: 'msg_click_list3', label: '리스트3 클릭수' },
    { id: 'msg_play_list3_start', label: '리스트3 재생수' },
    { id: 'msg_play_list3_3s', label: '리스트3 3초 재생수' },
    { id: 'msg_play_list3_5s', label: '리스트3 5초 재생수' },
    { id: 'msg_play_list3_10s', label: '리스트3 10초 재생수' },
    { id: 'msg_play_list3_15s', label: '리스트3 15초 재생수' },
    { id: 'msg_play_list3_30s', label: '리스트3 30초 재생수' },
    { id: 'msg_play_list3_60s', label: '리스트3 60초 재생수' },
    { id: 'msg_click_list4', label: '리스트4 클릭수' },
    { id: 'msg_play_list4_start', label: '리스트4 재생수' },
    { id: 'msg_play_list4_3s', label: '리스트4 3초 재생수' },
    { id: 'msg_play_list4_5s', label: '리스트4 5초 재생수' },
    { id: 'msg_play_list4_10s', label: '리스트4 10초 재생수' },
    { id: 'msg_play_list4_15s', label: '리스트4 15초 재생수' },
    { id: 'msg_play_list4_30s', label: '리스트4 30초 재생수' },
    { id: 'msg_play_list4_60s', label: '리스트4 60초 재생수' },
    { id: 'msg_click_button1', label: '버튼1 클릭수' },
    { id: 'msg_click_button2', label: '버튼2 클릭수' },
    { id: 'msg_click_carousel1_button1', label: '캐러셀1 버튼1 클릭수' },
    { id: 'msg_click_carousel1_button2', label: '캐러셀1 버튼2 클릭수' },
    { id: 'msg_click_carousel2_button1', label: '캐러셀2 버튼1 클릭수' },
    { id: 'msg_click_carousel2_button2', label: '캐러셀2 버튼2 클릭수' },
    { id: 'msg_click_carousel3_button1', label: '캐러셀3 버튼1 클릭수' },
    { id: 'msg_click_carousel3_button2', label: '캐러셀3 버튼2 클릭수' },
    { id: 'msg_click_carousel4_button1', label: '캐러셀4 버튼1 클릭수' },
    { id: 'msg_click_carousel4_button2', label: '캐러셀4 버튼2 클릭수' },
    { id: 'msg_click_carousel5_button1', label: '캐러셀5 버튼1 클릭수' },
    { id: 'msg_click_carousel5_button2', label: '캐러셀5 버튼2 클릭수' },
    { id: 'msg_click_carousel6_button1', label: '캐러셀6 버튼1 클릭수' },
    { id: 'msg_click_carousel6_button2', label: '캐러셀6 버튼2 클릭수' },
    { id: 'msg_click_carousel1_coupon', label: '캐러셀1 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel2_coupon', label: '캐러셀2 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel3_coupon', label: '캐러셀3 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel4_coupon', label: '캐러셀4 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel5_coupon', label: '캐러셀5 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel6_coupon', label: '캐러셀6 쿠폰 버튼 클릭수' },
    { id: 'msg_click_carousel_more', label: '더보기 클릭수' },
    { id: 'msg_click_coupon', label: '쿠폰 버튼 클릭수' },
    { id: 'msg_click_others', label: '기타 클릭수' },
  ],
  AD_VIEW: [
    { id: 'adv_click_button1', label: '버튼1 클릭수' },
    { id: 'adv_click_button2', label: '버튼2 클릭수' },
    { id: 'adv_click_button_share', label: '공유 버튼 클릭수' },
    { id: 'adv_th1_view', label: '이미지/동영상1 노출수' },
    { id: 'adv_th2_view', label: '이미지/동영상2 노출수' },
    { id: 'adv_th3_view', label: '이미지/동영상3 노출수' },
    { id: 'adv_th4_view', label: '이미지/동영상4 노출수' },
    { id: 'adv_th5_view', label: '이미지/동영상5 노출수' },
    { id: 'adv_th6_view', label: '이미지/동영상6 노출수' },
    { id: 'adv_th7_view', label: '이미지/동영상7 노출수' },
    { id: 'adv_th8_view', label: '이미지/동영상8 노출수' },
    { id: 'adv_th9_view', label: '이미지/동영상9 노출수' },
    { id: 'adv_th10_view', label: '이미지/동영상10 노출수' },
    { id: 'adv_th1_video_play_auto', label: '동영상1 자동 재생수' },
    { id: 'adv_th2_video_play_auto', label: '동영상2 자동 재생수' },
    { id: 'adv_th3_video_play_auto', label: '동영상3 자동 재생수' },
    { id: 'adv_th4_video_play_auto', label: '동영상4 자동 재생수' },
    { id: 'adv_th5_video_play_auto', label: '동영상5 자동 재생수' },
    { id: 'adv_th6_video_play_auto', label: '동영상6 자동 재생수' },
    { id: 'adv_th7_video_play_auto', label: '동영상7 자동 재생수' },
    { id: 'adv_th8_video_play_auto', label: '동영상8 자동 재생수' },
    { id: 'adv_th9_video_play_auto', label: '동영상9 자동 재생수' },
    { id: 'adv_th10_video_play_auto', label: '동영상10 자동 재생수' },
    { id: 'adv_th1_video_play_touch', label: '동영상1 터치 재생수' },
    { id: 'adv_th2_video_play_touch', label: '동영상2 터치 재생수' },
    { id: 'adv_th3_video_play_touch', label: '동영상3 터치 재생수' },
    { id: 'adv_th4_video_play_touch', label: '동영상4 터치 재생수' },
    { id: 'adv_th5_video_play_touch', label: '동영상5 터치 재생수' },
    { id: 'adv_th6_video_play_touch', label: '동영상6 터치 재생수' },
    { id: 'adv_th7_video_play_touch', label: '동영상7 터치 재생수' },
    { id: 'adv_th8_video_play_touch', label: '동영상8 터치 재생수' },
    { id: 'adv_th9_video_play_touch', label: '동영상9 터치 재생수' },
    { id: 'adv_th10_video_play_touch', label: '동영상10 터치 재생수' },
    { id: 'adv_th1_video_play_3s', label: '동영상1 3초 재생수' },
    { id: 'adv_th2_video_play_3s', label: '동영상2 3초 재생수' },
    { id: 'adv_th3_video_play_3s', label: '동영상3 3초 재생수' },
    { id: 'adv_th4_video_play_3s', label: '동영상4 3초 재생수' },
    { id: 'adv_th5_video_play_3s', label: '동영상5 3초 재생수' },
    { id: 'adv_th6_video_play_3s', label: '동영상6 3초 재생수' },
    { id: 'adv_th7_video_play_3s', label: '동영상7 3초 재생수' },
    { id: 'adv_th8_video_play_3s', label: '동영상8 3초 재생수' },
    { id: 'adv_th9_video_play_3s', label: '동영상9 3초 재생수' },
    { id: 'adv_th10_video_play_3s', label: '동영상10 3초 재생수' },
  ],
  BIZ_BOARD_EXPANDABLE: [
    { id: 'expandable_imp', label: '노출수 (확장)' },
    { id: 'motion_video_play_auto', label: '동영상 자동 재생수 (모션)' },
    { id: 'expandable_video_play_auto', label: '동영상 자동 재생수 (확장)' },
    { id: 'motion_video_play_3s', label: '동영상 3초 재생수 (모션)' },
    { id: 'expandable_video_play_3s', label: '동영상 3초 재생수 (확장)' },
    { id: 'expandable_click_image1', label: '이미지 클릭수 (확장)' },
    { id: 'expandable_click_cta', label: '행동유도 클릭수 (확장)' },
    { id: 'expandable_viewable_goods_1', label: '이미지1 노출수 (확장)' },
    { id: 'expandable_click_goods_1', label: '이미지1 클릭수 (확장)' },
    { id: 'expandable_viewable_goods_2', label: '이미지2 노출수 (확장)' },
    { id: 'expandable_click_goods_2', label: '이미지2 클릭수 (확장)' },
    { id: 'expandable_viewable_goods_3', label: '이미지3 노출수 (확장)' },
    { id: 'expandable_click_goods_3', label: '이미지3 클릭수 (확장)' },
    { id: 'expandable_viewable_goods_4', label: '이미지4 노출수 (확장)' },
    { id: 'expandable_click_goods_4', label: '이미지4 클릭수 (확장)' },
    { id: 'expandable_viewable_goods_5', label: '이미지5 노출수 (확장)' },
    { id: 'expandable_click_goods_5', label: '이미지5 클릭수 (확장)' },
  ],
  SPONSORED_BOARD: [
    { id: 'channel_home_click', label: '채널 홈 클릭수' },
    { id: 'channel_add_click', label: '채널 추가 클릭수' },
    { id: 'article1_click', label: '아티클1 클릭수' },
    { id: 'article2_click', label: '아티클2 클릭수' },
    { id: 'article3_click', label: '아티클3 클릭수' },
    { id: 'article4_click', label: '아티클4 클릭수' },
    { id: 'article5_click', label: '아티클5 클릭수' },
    { id: 'article6_click', label: '아티클6 클릭수' },
    { id: 'article7_click', label: '아티클7 클릭수' },
    { id: 'article8_click', label: '아티클8 클릭수' },
    { id: 'article9_click', label: '아티클9 클릭수' },
    { id: 'article10_click', label: '아티클10 클릭수' },
  ],
  TRIGGER: [{ id: 'trigger_viewable', label: '유도 영역 노출수' }],
}

/**
 * Array.flat() 사용불가(IE).
 */
const DashboardTableMetricIds = Object.values(DashboardTableMetric).reduce(
  (arr, metrics) => arr.concat(metrics),
  []
)

const DashboardTableMetricIdsByGroup =
  DashboardMetricGroupEnumV2.values().reduce(
    (arr, group, index) =>
      arr.concat({
        [group]: Object.values(DashboardTableMetric)[index].map(v => ({
          id: v.id,
          label: v.label,
        })),
      }),
    []
  )

/**
 * @param params
 * @param params.campaignType CampaignTypeEnum.Type
 * @param params.goal  GoalEnum.Type
 * @param params.objective { detailType: ObjectiveDetailTypeEnum.Type}
 */
const getMetricGroupsByCampaignTypeGoal = ({ campaignType, goal }) => {
  const pixelAndSdkMetricsForCPT = DashboardTableMetric.PIXEL_AND_SDK.filter(
    ({ id }) =>
      ![
        'conv_purchase_p_per_cost_1d',
        'conv_estimated_purchase_p_per_cost_1d',
        'conv_purchase_p_per_cost_7d',
        'conv_estimated_purchase_p_per_cost_7d',
      ].includes(id)
  )

  switch (campaignType) {
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
      return [
        DashboardTableMetric.COST,
        DashboardTableMetric.DEFAULT,
        DashboardTableMetric.PIXEL_AND_SDK,
        DashboardTableMetric.PLUS_FRIEND,
        DashboardTableMetric.AD_VIEW,
        DashboardTableMetric.BIZ_BOARD_EXPANDABLE,
      ]
    }

    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach'].includes(id)
        ),
        pixelAndSdkMetricsForCPT,
        DashboardTableMetric.AD_VIEW,
        DashboardTableMetric.BIZ_BOARD_EXPANDABLE,
      ]
    }

    case CampaignTypeEnum.Type.PC_TALK_BOTTOM: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach', 'video_play_3s', 'vtr'].includes(id)
        ),
        pixelAndSdkMetricsForCPT,
        DashboardTableMetric.VIDEO.filter(({ id }) =>
          [
            'video_play_auto',
            'video_play_25p',
            'video_play_50p',
            'video_play_75p',
            'video_play_100p',
          ].includes(id)
        ),
      ]
    }

    case CampaignTypeEnum.Type.PC_TALK_RICH_POP: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach'].includes(id)
        ),
        pixelAndSdkMetricsForCPT,
      ]
    }

    case CampaignTypeEnum.Type.DISPLAY: {
      if (goal === GoalEnum.Type.VISITING) {
        return [
          DashboardTableMetric.COST,
          DashboardTableMetric.DEFAULT,
          DashboardTableMetric.PIXEL_AND_SDK,
          DashboardTableMetric.VIDEO,
          DashboardTableMetric.CATALOG_SLIDE,
        ]
      } else if (goal === GoalEnum.Type.CONVERSION) {
        return [
          DashboardTableMetric.COST,
          DashboardTableMetric.DEFAULT,
          DashboardTableMetric.PIXEL_AND_SDK,
          DashboardTableMetric.PLUS_FRIEND,
          DashboardTableMetric.VIDEO,
          DashboardTableMetric.CATALOG_SLIDE,
        ]
      }

      break
    }

    case CampaignTypeEnum.Type.PERSONAL_MESSAGE:
    case CampaignTypeEnum.Type.TALK_CHANNEL: {
      if (goal === GoalEnum.Type.REACH) {
        return [
          DashboardTableMetric.COST,
          DashboardTableMetric.MESSAGE_SENDING,
          DashboardTableMetric.PIXEL_AND_SDK,
          DashboardTableMetric.MESSAGE,
          DashboardTableMetric.AD_VIEW,
        ]
      }

      break
    }

    case CampaignTypeEnum.Type.DAUM_SHOPPING: {
      if (goal === GoalEnum.Type.REACH) {
        return [
          DashboardTableMetric.DEFAULT.filter(({ id }) => id === 'click'),
          pixelAndSdkMetricsForCPT,
        ]
      }

      break
    }

    case CampaignTypeEnum.Type.VIDEO:
    case CampaignTypeEnum.Type.KAKAO_TV: {
      return [
        DashboardTableMetric.COST,
        DashboardTableMetric.DEFAULT,
        DashboardTableMetric.PIXEL_AND_SDK,
        DashboardTableMetric.VIDEO,
      ]
    }

    case CampaignTypeEnum.Type.SPONSORED_BOARD: {
      return [
        DashboardTableMetric.COST,
        DashboardTableMetric.DEFAULT,
        DashboardTableMetric.PIXEL_AND_SDK,
        DashboardTableMetric.PLUS_FRIEND,
        DashboardTableMetric.SPONSORED_BOARD,
      ]
    }

    case CampaignTypeEnum.Type.ELECTION_2022_03:
    case CampaignTypeEnum.Type.ELECTION_2022_06: {
      return [
        DashboardTableMetric.COST,
        DashboardTableMetric.DEFAULT.filter(
          ({ id }) => id !== 'video_play_3s' && id !== 'cost_per_video_play_3s'
        ),
      ]
    }
    case CampaignTypeEnum.Type.PRODUCT_CATALOG: {
      return [
        DashboardTableMetric.COST,
        DashboardTableMetric.DEFAULT,
        DashboardTableMetric.CATALOG_SLIDE,
        DashboardTableMetric.PIXEL_AND_SDK,
      ]
    }

    case CampaignTypeEnum.Type.ELECTION_2024_04: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach', 'video_play_3s'].includes(id)
        ),
        DashboardTableMetric.PIXEL_AND_SDK,
        DashboardTableMetric.VIDEO,
      ]
    }

    case CampaignTypeEnum.Type.FOCUS_FULL_VIEW: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach', 'video_play_3s', 'vtr'].includes(id)
        ),
        pixelAndSdkMetricsForCPT,
        DashboardTableMetric.PLUS_FRIEND,
        DashboardTableMetric.VIDEO,
      ]
    }

    case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
      return [
        DashboardTableMetric.DEFAULT.filter(({ id }) =>
          ['imp', 'click', 'ctr', 'reach', 'video_play_3s', 'vtr'].includes(id)
        ),
        pixelAndSdkMetricsForCPT,
        DashboardTableMetric.PLUS_FRIEND,
        DashboardTableMetric.VIDEO,
        DashboardTableMetric.TRIGGER,
      ]
    }

    default: {
      break
    }
  }

  return []
}

/**
 * @param param
 * @param param.campaignType CampaignTypeEnum.Type
 * @param param.goal GoalEnum.Type
 */
const getMetricsByCampaignTypeGoal = ({ campaignType, goal }) => {
  return getMetricGroupsByCampaignTypeGoal({
    campaignType,
    goal,
  }).reduce(
    (arr, group) => arr.concat(group?.map(({ id }) => id)).filter(Boolean),
    []
  )
}

const getMetricSuffix = ({ metricCode }) => {
  if (DASHBOARD_CVR_SUFFIX_AVAILABLE_METRICS.includes(metricCode)) {
    return '%'
  } else {
    return ''
  }
}

export {
  DashboardTableMetric,
  DashboardTableMetricIds,
  DashboardTableMetricIdsByGroup,
  getMetricGroupsByCampaignTypeGoal,
  getMetricsByCampaignTypeGoal,
  getMetricSuffix,
}
