import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import ObjectiveTypeEnum from '../../enums/ObjectiveTypeEnum'
import {
  isAdGroupModifyStatus,
  isAdGroupScheduleReady,
} from '../advertise/campaignAdGroup'
import PricingTypeEnum from '../../enums/PricingTypeEnum'
import BidStrategyEnum from '../../enums/BidStrategyEnum'
import PlacementEnum from '../../enums/PlacementEnum'
import { List, Map } from 'immutable'
import AdStatusEnum from '../../enums/AdStatusEnum'
import PacingEnum from '../../enums/PacingEnum'
import AutoBidPurposeEnum from '../../enums/AutoBidPurposeEnum'
import ObjectiveDetailTypeEnum from '../../enums/ObjectiveDetailTypeEnum'
import DeviceTypeEnum from '../../enums/DeviceTypeEnum'
import DeviceOsTypeEnum from '../../enums/DeviceOsTypeEnum'
import SectionCategoryEnum from '../../enums/SectionCategoryEnum'
import BidStrategyTargetEnum from '../../enums/BidStrategyTargetEnum'
import AdAccountExtraEnum from '../../enums/AdAccountExtraEnum'

const AdGroupConstraints = {
  KakaoTV: {
    /**
     * @deprecated
     */
    PERIOD_BUDGET_AMOUNT_MIN: 10,
    /**
     * @deprecated
     */
    PERIOD_BUDGET_AMOUNT_MAX: 1_000_000_000,
    /**
     * @deprecated
     */
    PERIOD_BUDGET_AMOUNT_UNIT: 10,
  },
  KakaoOfficialChannel: {
    BID_AMOUNT_MIN: 30,
    BID_AMOUNT_MAX: 10_000,
    BID_AMOUNT_UNIT: 10,
  },
  /**
   *  전환 목표일때 전환추적 카드 숨김처리 (코어 제약으로는 보여지도록 되어있지만 프론트 제약사항으로 안보여지게 처리함)
   *  https://jira.daumkakao.com/browse/KAMOQA-17042
   *
   *  캠페인 유형이 카탈로그인 경우에는 전환추적 노출 되도록 함.
   */
  isConversionRequired: ({ campaignType, goal }) => {
    if (
      campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG &&
      goal === GoalEnum.Type.CONVERSION
    ) {
      return false
    } else {
      return true
    }
  },

  isConversionTrackingOptionAvailable: ({ campaignType, goal }) => {
    switch (campaignType) {
      case CampaignTypeEnum.Type.TALK_CHANNEL:
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE:
      case CampaignTypeEnum.Type.DAUM_SHOPPING:
      case CampaignTypeEnum.Type.VIDEO:
      case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
      case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
        return true
      }

      case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
      case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
      case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
      case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
      case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
      case CampaignTypeEnum.Type.ELECTION_2024_04: {
        return goal === GoalEnum.Type.REACH || goal === GoalEnum.Type.VISITING
      }

      default: {
        return goal === GoalEnum.Type.VISITING
      }
    }
  },

  isObjectiveRequired: ({ campaignType, goal }) => {
    switch (goal) {
      case GoalEnum.Type.REACH: {
        return (
          campaignType === CampaignTypeEnum.Type.TALK_CHANNEL ||
          campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE ||
          campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
        )
      }

      case GoalEnum.Type.CONVERSION: {
        return (
          campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
          campaignType === CampaignTypeEnum.Type.DISPLAY ||
          campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG
        )
      }

      default: {
        return false
      }
    }
  },

  isAudienceNotRequired: ({ campaignType }) => {
    switch (campaignType) {
      case CampaignTypeEnum.Type.DAUM_SHOPPING:
      case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return true
      }

      default: {
        return false
      }
    }
  },

  isDevicePlacementRequired: ({ campaignType }) => {
    switch (campaignType) {
      case CampaignTypeEnum.Type.KAKAO_TV:
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return false
      }

      default: {
        return true
      }
    }
  },

  isNotAvailableModify: ({ campaignType, goal, adGroupForm, location }) => {
    const {
      schedule: { beginDate, beginTime },
      status,
    } = adGroupForm || {}

    const isAdGroupModify = isAdGroupModifyStatus(location)
    const isAdGroupReady = isAdGroupScheduleReady({
      beginDate,
      beginTime,
      campaignType,
      goal,
    })

    switch (campaignType) {
      case CampaignTypeEnum.Type.TALK_CHANNEL: {
        return (
          (isAdGroupModify && !isAdGroupReady) ||
          status?.includes(AdStatusEnum.Type.CANCELED)
        )
      }

      case CampaignTypeEnum.Type.KAKAO_TV: {
        if (goal === GoalEnum.Type.REACH) {
          return isAdGroupModify
        } else {
          return false
        }
      }

      case CampaignTypeEnum.Type.ELECTION_2022_03:
      case CampaignTypeEnum.Type.ELECTION_2022_06:
      case CampaignTypeEnum.Type.DAUM_SHOPPING: {
        return isAdGroupModify
      }

      case CampaignTypeEnum.Type.ELECTION_2024_04:
      case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
      case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
      case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
      case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
        return true
      }

      default: {
        return false
      }
    }
  },

  isSmartMessageRequired: ({ campaignType }) => {
    return campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
  },

  /**
   * 전환일 경우 objectiveType 상관없이 모수 미제공 (https://jira.daumkakao.com/browse/KAMOQA-29732)
   */
  isPopulationRequired: ({ campaignType, goal }) => {
    if (
      [
        CampaignTypeEnum.Type.DISPLAY,
        CampaignTypeEnum.Type.TALK_BIZ_BOARD,
        CampaignTypeEnum.Type.TALK_CHANNEL,
        CampaignTypeEnum.Type.VIDEO,
        CampaignTypeEnum.Type.ELECTION_2022_06,
      ].includes(campaignType)
    ) {
      // if (objectiveType !== ObjectiveTypeEnum.Type.PIXEL_AND_SDK) return true
      if ([GoalEnum.Type.VISITING, GoalEnum.Type.REACH].includes(goal))
        return true
    }

    return false
  },

  canModifyBidAmount: ({ pricingType, bidStrategy, campaignType, goal }) => {
    const isElectionReach =
      CampaignTypeEnum.isElectionCampaignType(campaignType) &&
      goal === GoalEnum.Type.REACH

    const isDeprecatedCampaignType = CampaignTypeEnum.isDeprecated(campaignType)

    return (
      !isDeprecatedCampaignType &&
      !isElectionReach &&
      [
        PricingTypeEnum.Type.CPC,
        PricingTypeEnum.Type.CPM,
        PricingTypeEnum.Type.CPA,
        PricingTypeEnum.Type.CPV,
      ].includes(pricingType) &&
      bidStrategy === BidStrategyEnum.Type.MANUAL
    )
  },

  isTargetCollapsable: ({ campaignType, goal }) => {
    return (
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
      goal === GoalEnum.Type.REACH
    )
  },

  isPreContractRequired: ({ campaignType }) => {
    return (
      [
        CampaignTypeEnum.Type.DAUM_SHOPPING,
        CampaignTypeEnum.Type.TALK_CHANNEL,
        CampaignTypeEnum.Type.KAKAO_TV,
      ].includes(campaignType) ||
      CampaignTypeEnum.isContractCampaignType(campaignType)
    )
  },

  isSkanType: ({ campaignType, goal, objectiveType, objectiveDetailType }) => {
    if (goal !== GoalEnum.Type.CONVERSION) {
      return false
    }

    return (
      [
        CampaignTypeEnum.Type.DISPLAY,
        CampaignTypeEnum.Type.TALK_BIZ_BOARD,
      ].includes(campaignType) &&
      objectiveType === ObjectiveTypeEnum.Type.PIXEL_AND_SDK &&
      objectiveDetailType === ObjectiveDetailTypeEnum.Type.APP_INSTALL
    )
  },

  Schedule: {
    isEndDateUnlimitedRequired: ({ campaignType }) => {
      return ![
        CampaignTypeEnum.Type.KAKAO_TV,
        CampaignTypeEnum.Type.ELECTION_2022_03,
        CampaignTypeEnum.Type.ELECTION_2022_06,
      ].includes(campaignType)
    },
  },

  Targeting: {
    PlusFriendGroup: {
      isSelectedPlusFriendGroup: ({ plusFriendGroupTargetings }) =>
        plusFriendGroupTargetings?.size > 0,
    },

    SideBar: {
      isCustomTargetSummaryRequired: ({ campaignType, goal }) => {
        switch (campaignType) {
          case CampaignTypeEnum.Type.ELECTION_2022_03:
          case CampaignTypeEnum.Type.ELECTION_2022_06:
          case CampaignTypeEnum.Type.ELECTION_2024_04:
          case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
          case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
          case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
          case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
          case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
          case CampaignTypeEnum.Type.PROFILE_FULL_VIEW:
          case CampaignTypeEnum.Type.PRODUCT_CATALOG:
          case CampaignTypeEnum.Type.DAUM_SHOPPING: {
            return false
          }

          case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
            return goal !== GoalEnum.Type.REACH
          }

          default: {
            return true
          }
        }
      },
    },
  },

  Placement: {
    isPlacementDefaultSelected: () => {
      return true
    },

    isAllAvailablePlacement: ({ campaignType, goal }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.DISPLAY:
        case CampaignTypeEnum.Type.VIDEO:
        case CampaignTypeEnum.Type.PRODUCT_CATALOG:
          return true

        case CampaignTypeEnum.Type.TALK_CHANNEL:
        case CampaignTypeEnum.Type.DAUM_SHOPPING:
          return false

        case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
          return goal !== GoalEnum.Type.REACH

        default:
          return false
      }
    },

    isAvailableAdServingCategory: ({ placement, campaignType }) => {
      return (
        placement === PlacementEnum.Type.NETWORK &&
        campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG
      )
    },

    isChatTabOnly: ({
      sectionCategories = Map(),
      devicePlacementConstraints = Map(),
    }) => {
      return (
        sectionCategories.size === 1 &&
        devicePlacementConstraints.size === 1 &&
        sectionCategories.get(0)?.get('code') ===
          SectionCategoryEnum.getCode(SectionCategoryEnum.Type.CHAT_TAB) &&
        devicePlacementConstraints.get(0)?.get('placement') ===
          PlacementEnum.Type.KAKAO_TALK
      )
    },

    isFixed: ({ campaignType, placement }) => {
      if (
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT ||
        campaignType === CampaignTypeEnum.Type.TALK_CHANNEL ||
        campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM ||
        campaignType === CampaignTypeEnum.Type.PC_TALK_RICH_POP ||
        campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW ||
        campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW
      ) {
        return placement === PlacementEnum.Type.KAKAO_TALK
      }

      if (
        campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
        campaignType === CampaignTypeEnum.Type.ELECTION_2024_04 ||
        CampaignTypeEnum.isElectionCampaignType(campaignType)
      ) {
        return placement === PlacementEnum.Type.DAUM
      }

      return false
    },
  },

  Device: {
    isSelectableDeviceConstraints: ({
      campaignType,
      goal,
      objectiveType,
      objectiveDetailType,
    }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.ELECTION_2022_03:
        case CampaignTypeEnum.Type.ELECTION_2022_06:
        case CampaignTypeEnum.Type.ELECTION_2024_04: {
          return {
            pcAvailable: true,
            mobileAvailable: true,
          }
        }

        case CampaignTypeEnum.Type.DISPLAY: {
          if (
            goal === GoalEnum.Type.CONVERSION &&
            (objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL ||
              (objectiveType === ObjectiveTypeEnum.Type.PIXEL_AND_SDK &&
                objectiveDetailType ===
                  ObjectiveDetailTypeEnum.Type.APP_INSTALL))
          ) {
            return {
              pcAvailable: false,
              mobileAvailable: true,
            }
          }

          return { pcAvailable: true, mobileAvailable: true }
        }

        case CampaignTypeEnum.Type.TALK_CHANNEL: {
          return { pcAvailable: false, mobileAvailable: true }
        }

        case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
          if (goal === GoalEnum.Type.REACH) {
            return { pcAvailable: false, mobileAvailable: true }
          }

          return { pcAvailable: false, mobileAvailable: true }
        }

        case CampaignTypeEnum.Type.DAUM_SHOPPING:
        case CampaignTypeEnum.Type.VIDEO:
        case CampaignTypeEnum.Type.PRODUCT_CATALOG: {
          return { pcAvailable: true, mobileAvailable: true }
        }

        case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
        case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
          return { pcAvailable: true, mobileAvailable: false }

        case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
        case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
        case CampaignTypeEnum.Type.PROFILE_FULL_VIEW:
          return { pcAvailable: false, mobileAvailable: true }

        default:
          return {}
      }
    },

    isAllAvailableDeviceTypes: ({
      campaignType,
      goal,
      objectiveType,
      objectiveDetailType,
    }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.DISPLAY: {
          if (goal === GoalEnum.Type.CONVERSION) {
            return (
              objectiveType === ObjectiveTypeEnum.Type.PIXEL_AND_SDK &&
              objectiveDetailType !== ObjectiveDetailTypeEnum.Type.APP_INSTALL
            )
          }
          return goal === GoalEnum.Type.VISITING
        }

        case CampaignTypeEnum.Type.TALK_CHANNEL: {
          return goal === GoalEnum.Type.REACH
        }

        case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
        case CampaignTypeEnum.Type.DAUM_SHOPPING: {
          return false
        }

        case CampaignTypeEnum.Type.VIDEO:
        case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
          return true
        }

        case CampaignTypeEnum.Type.PRODUCT_CATALOG: {
          return goal === GoalEnum.Type.CONVERSION
        }

        default:
          return false
      }
    },

    // 광고그룹 디바이스 타입 목록의 디폴트 값. MOBILE 일 경우 ANDROID, IOS 로 분리.
    availableDeviceTypes: ({ devicePlacementConstraints }) => {
      const deviceTypes =
        devicePlacementConstraints
          ?.groupBy(v => v.get('device'))
          ?.map(v => v.first())
          ?.keySeq() || []

      return List(
        deviceTypes.reduce((prev, item) => {
          return prev.concat(
            item.includes(DeviceTypeEnum.Type.MOBILE)
              ? DeviceOsTypeEnum.values().filter(
                  v => v !== DeviceOsTypeEnum.Type.PC
                )
              : item
          )
        }, [])
      )
    },

    isShowDeviceSettingOption: ({ campaignType, goal }) => {
      return (
        campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED &&
        goal !== GoalEnum.Type.REACH
      )
    },

    /**
     * https://jira.daumkakao.com/browse/KAMOQA-30777
     *  - deviceFixed 조건
     *    - 더이상 체크 해제할 수 없는 경우 (캠페인 유형, objective 속성) 등을 종합적으로 고려.
     */
    isDeviceFixed: ({ campaignType, objectiveType, objectiveDetailType }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.DISPLAY: {
          return (
            objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL ||
            objectiveDetailType === ObjectiveDetailTypeEnum.Type.APP_INSTALL // skan 설정 여부 상관X
          )
        }

        case CampaignTypeEnum.Type.TALK_BIZ_BOARD:
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD_CHAT_TAB_CPT:
        case CampaignTypeEnum.Type.TALK_CHANNEL:
        case CampaignTypeEnum.Type.ELECTION_2024_04:
        case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
        case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
        case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
        case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
          return true
        }

        default: {
          return false
        }
      }
    },
  },

  Pacing: {
    getAvailableDefaultPacingType: ({ campaignType, goal }) => {
      if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
        return PacingEnum.Type.NORMAL
      }

      // 비즈보드+도달 = NORMAL
      if (
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        goal === GoalEnum.Type.REACH
      ) {
        return PacingEnum.Type.NORMAL
      }

      // 전환+픽셀이거나, 지정한 특정 캠페인 유형 이외의 방문, 도달일 경우 = NONE
      if (
        goal === GoalEnum.Type.VISITING ||
        goal === GoalEnum.Type.REACH ||
        goal === GoalEnum.Type.CONVERSION
      ) {
        return PacingEnum.Type.NONE
      }

      // 카탈로그+전환
      if (
        campaignType === CampaignTypeEnum.Type.PRODUCT_CATALOG &&
        goal === GoalEnum.Type.CONVERSION
      ) {
        return PacingEnum.Type.NONE
      }

      // 디폴트 초기값
      return PacingEnum.Type.QUICK
    },
  },

  BidStrategy: {
    getAvailableDefaultBidStrategyType: ({ campaignType, goal }) => {
      switch (goal) {
        case GoalEnum.Type.VISITING: {
          if (campaignType === CampaignTypeEnum.Type.SPONSORED_BOARD) {
            return BidStrategyEnum.Type.MANUAL
          } else {
            return BidStrategyEnum.Type.AUTOBID
          }
        }

        case GoalEnum.Type.CONVERSION: {
          return BidStrategyEnum.Type.AUTOBID
        }

        default: {
          return BidStrategyEnum.Type.MANUAL
        }
      }
    },

    getAutoBidPurposeType: ({ campaignType, goal }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
          if (goal === GoalEnum.Type.VISITING) {
            return AutoBidPurposeEnum.Type.CLICK
          }

          if (goal === GoalEnum.Type.CONVERSION) {
            // KAMOQA-21891 objectiveType 톡채널 유형일때도 CONVERSION 으로 변경됨.
            return AutoBidPurposeEnum.Type.CONVERSION
          }

          return null
        }
        case CampaignTypeEnum.Type.PRODUCT_CATALOG:
        case CampaignTypeEnum.Type.DISPLAY: {
          if (goal === GoalEnum.Type.VISITING) {
            return AutoBidPurposeEnum.Type.CLICK
          }

          if (goal === GoalEnum.Type.CONVERSION) {
            // KAMOQA-21891 objectiveType 톡채널 유형일때도 CONVERSION 으로 변경됨.
            return AutoBidPurposeEnum.Type.CONVERSION
          }

          return null
        }

        default: {
          return null
        }
      }
    },

    getAvailableAutoBidPurposeType: ({
      campaignType,
      goal,
      objectiveDetailType,
    }) => {
      switch (campaignType) {
        case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
          if (goal === GoalEnum.Type.VISITING) {
            return [AutoBidPurposeEnum.Type.CLICK]
          }

          if (goal === GoalEnum.Type.CONVERSION) {
            if (objectiveDetailType === ObjectiveDetailTypeEnum.Type.PURCHASE) {
              return [
                AutoBidPurposeEnum.Type.CONVERSION,
                AutoBidPurposeEnum.Type.CONVERSION_VALUE,
              ]
            } else {
              // KAMOQA-21891 objectiveType 톡채널 유형일때도 CONVERSION 으로 변경됨.
              return [AutoBidPurposeEnum.Type.CONVERSION]
            }
          }

          return []
        }
        case CampaignTypeEnum.Type.PRODUCT_CATALOG:
        case CampaignTypeEnum.Type.DISPLAY: {
          if (goal === GoalEnum.Type.VISITING) {
            return [AutoBidPurposeEnum.Type.CLICK]
          }

          if (goal === GoalEnum.Type.CONVERSION) {
            if (
              [
                ObjectiveDetailTypeEnum.Type.PURCHASE,
                ObjectiveDetailTypeEnum.Type.CATALOG_PRODUCT_PURCHASE,
              ].includes(objectiveDetailType)
            ) {
              return [
                AutoBidPurposeEnum.Type.CONVERSION,
                AutoBidPurposeEnum.Type.CONVERSION_VALUE,
              ]
            } else {
              // KAMOQA-21891 objectiveType 톡채널 유형일때도 CONVERSION 으로 변경됨.
              return [AutoBidPurposeEnum.Type.CONVERSION]
            }
          }

          return []
        }

        default: {
          return []
        }
      }
    },

    getAutoBidPurposeTypeByBidStrategyTarget: ({ bidStrategyTarget }) => {
      switch (bidStrategyTarget) {
        case BidStrategyTargetEnum.Type.TARGET_CPC:
          return AutoBidPurposeEnum.Type.CLICK
        case BidStrategyTargetEnum.Type.TARGET_CPA:
          return AutoBidPurposeEnum.Type.CONVERSION
        case BidStrategyTargetEnum.Type.TARGET_ROAS:
        case BidStrategyTargetEnum.Type.MAXIMIZE_CONVERSION_VALUE:
          return AutoBidPurposeEnum.Type.CONVERSION_VALUE
        default:
          return null
      }
    },
  },

  BidStrategyTarget: {
    getAvailableBidStrategyTarget: ({ autoBidPurpose }) => {
      switch (autoBidPurpose) {
        case AutoBidPurposeEnum.Type.CLICK:
          return BidStrategyTargetEnum.Type.TARGET_CPC
        case AutoBidPurposeEnum.Type.CONVERSION:
          return BidStrategyTargetEnum.Type.TARGET_CPA
        case AutoBidPurposeEnum.Type.CONVERSION_VALUE:
          return BidStrategyTargetEnum.Type.TARGET_ROAS
        default:
          return null
      }
    },
  },

  Demographic: {
    isNationalOnlyLocationRequired: ({ campaignType }) => {
      return [
        CampaignTypeEnum.Type.TALK_BIZ_BOARD,
        CampaignTypeEnum.Type.SPONSORED_BOARD,
      ].includes(campaignType)
    },

    isAdultTargetingRequired: ({ campaignType }) => {
      return [
        CampaignTypeEnum.Type.DISPLAY,
        CampaignTypeEnum.Type.TALK_BIZ_BOARD,
        CampaignTypeEnum.Type.SPONSORED_BOARD,
        CampaignTypeEnum.Type.VIDEO,
        CampaignTypeEnum.Type.PRODUCT_CATALOG,
      ].includes(campaignType)
    },

    isAgeVerificationMessageRequired: ({ campaignType }) => {
      return campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
    },
  },
}

export default AdGroupConstraints
